"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var tailwind_merge_1 = require("tailwind-merge");
// ✔ create resizable's container component
/**
 * #### Resizable's Container Component ####
 * @param { ResizableContainerProps } props - What is the properties of the resizable's container component?
 * @returns { React.ReactNode } Resizable's container component
 *
 * @example
 * ```typescriptreact
 * import { ResizableContainer } from '@/components/molecules`;
 * <ResizableContainer className={CLASS_NAME}>{CHILDREN}</ResizableContainer>
 * ```
 *
 * @example
 * ```typescriptreact
 * import { Resizable } from '@/components/molecules`;
 * <Resizable.Container className={CLASS_NAME}>{CHILDREN}</Resizable.Container>
 * ```
 *
 * @version 1.0.0
 * @since 10.3.0
 * @author Wunna
 */
function ResizableContainer(props) {
    return (0, jsx_runtime_1.jsx)("div", { className: (0, tailwind_merge_1.twMerge)('tw-flex tw-h-full tw-w-full tw-flex-col', props.className), children: props.children });
}
exports.default = ResizableContainer;
