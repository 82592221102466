"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var Input_1 = require("@/components/atoms/Inputs/Input");
var button_1 = require("@/components/ui/button");
var react_1 = require("react");
function ChangePassowrdForm() {
    var _a = (0, react_1.useState)(true), show = _a[0], setShow = _a[1];
    var _b = (0, react_1.useState)(''), password = _b[0], setPassword = _b[1];
    var handleSumit = function (e) {
        e.preventDefault();
        console.log(password);
    };
    return ((0, jsx_runtime_1.jsxs)("form", { onSubmit: handleSumit, className: 'tw-relative tw-flex tw-flex-col tw-items-start', children: [(0, jsx_runtime_1.jsx)(Input_1.Input, { type: show ? 'password' : 'text', label: 'Current Password', value: password, onChange: function (e) { return setPassword(e.target.value); }, className: 'tw-w-full md:tw-w-[400px]', name: 'current-password', isPasswordInput: true, passwordToggle: function () { return setShow(function (prev) { return !prev; }); } }), (0, jsx_runtime_1.jsx)(button_1.Button, { type: 'submit', size: 'sm', className: 'tw-mt-3 tw-text-background-1', disabled: !password, children: "Change Password" })] }));
}
exports.default = ChangePassowrdForm;
