"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateRangePicker = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var button_1 = require("../../../ui/button");
var calendar_1 = require("../../../ui/calendar");
var formatDate = function (date, locale) {
    if (locale === void 0) { locale = 'en-us'; }
    return date.toLocaleDateString(locale, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });
};
// Define presets
var PRESETS = [
    { name: 'today', label: 'Today' },
    { name: 'yesterday', label: 'Yesterday' },
    { name: 'last7', label: 'Last 7 days' },
    { name: 'last14', label: 'Last 14 days' },
    { name: 'last30', label: 'Last 30 days' },
    { name: 'thisWeek', label: 'This Week' },
    { name: 'lastWeek', label: 'Last Week' },
    { name: 'thisMonth', label: 'This Month' },
    { name: 'lastMonth', label: 'Last Month' },
];
/** The DateRangePicker component allows a user to select a range of dates */
var DateRangePicker = function (_a) {
    var _b = _a.initialDateFrom, initialDateFrom = _b === void 0 ? new Date(new Date().setHours(0, 0, 0, 0)) : _b, initialDateTo = _a.initialDateTo, initialCompareFrom = _a.initialCompareFrom, initialCompareTo = _a.initialCompareTo, onUpdate = _a.onUpdate, _c = _a.align, align = _c === void 0 ? 'end' : _c, _d = _a.locale, locale = _d === void 0 ? 'en-US' : _d;
    var _e = (0, react_1.useState)(false), isOpen = _e[0], setIsOpen = _e[1];
    var _f = (0, react_1.useState)({
        from: new Date(new Date(initialDateFrom).setHours(0, 0, 0, 0)),
        to: initialDateTo
            ? new Date(new Date(initialDateTo).setHours(0, 0, 0, 0))
            : new Date(new Date(initialDateFrom).setHours(0, 0, 0, 0)),
    }), range = _f[0], setRange = _f[1];
    var _g = (0, react_1.useState)(initialCompareFrom
        ? {
            from: new Date(new Date(initialCompareFrom).setHours(0, 0, 0, 0)),
            to: initialCompareTo
                ? new Date(new Date(initialCompareTo).setHours(0, 0, 0, 0))
                : new Date(new Date(initialCompareFrom).setHours(0, 0, 0, 0)),
        }
        : undefined), rangeCompare = _g[0], setRangeCompare = _g[1];
    // Refs to store the values of range and rangeCompare when the date picker is opened
    var openedRangeRef = (0, react_1.useRef)();
    var openedRangeCompareRef = (0, react_1.useRef)();
    var _h = (0, react_1.useState)(undefined), selectedPreset = _h[0], setSelectedPreset = _h[1];
    var _j = (0, react_1.useState)(typeof window !== 'undefined' ? window.innerWidth < 960 : false), isSmallScreen = _j[0], setIsSmallScreen = _j[1];
    (0, react_1.useEffect)(function () {
        var handleResize = function () {
            setIsSmallScreen(window.innerWidth < 960);
        };
        window.addEventListener('resize', handleResize);
        // Clean up event listener on unmount
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    var getPresetRange = function (presetName) {
        var preset = PRESETS.find(function (_a) {
            var name = _a.name;
            return name === presetName;
        });
        if (!preset)
            throw new Error("Unknown date range preset: ".concat(presetName));
        var from = new Date();
        var to = new Date();
        var first = from.getDate() - from.getDay();
        switch (preset.name) {
            case 'today':
                from.setHours(0, 0, 0, 0);
                to.setHours(23, 59, 59, 999);
                break;
            case 'yesterday':
                from.setDate(from.getDate() - 1);
                from.setHours(0, 0, 0, 0);
                to.setDate(to.getDate() - 1);
                to.setHours(23, 59, 59, 999);
                break;
            case 'last7':
                from.setDate(from.getDate() - 6);
                from.setHours(0, 0, 0, 0);
                to.setHours(23, 59, 59, 999);
                break;
            case 'last14':
                from.setDate(from.getDate() - 13);
                from.setHours(0, 0, 0, 0);
                to.setHours(23, 59, 59, 999);
                break;
            case 'last30':
                from.setDate(from.getDate() - 29);
                from.setHours(0, 0, 0, 0);
                to.setHours(23, 59, 59, 999);
                break;
            case 'thisWeek':
                from.setDate(first);
                from.setHours(0, 0, 0, 0);
                to.setHours(23, 59, 59, 999);
                break;
            case 'lastWeek':
                from.setDate(from.getDate() - 7 - from.getDay());
                to.setDate(to.getDate() - to.getDay() - 1);
                from.setHours(0, 0, 0, 0);
                to.setHours(23, 59, 59, 999);
                break;
            case 'thisMonth':
                from.setDate(1);
                from.setHours(0, 0, 0, 0);
                to.setHours(23, 59, 59, 999);
                break;
            case 'lastMonth':
                from.setMonth(from.getMonth() - 1);
                from.setDate(1);
                from.setHours(0, 0, 0, 0);
                to.setDate(0);
                to.setHours(23, 59, 59, 999);
                break;
        }
        return { from: from, to: to };
    };
    var setPreset = function (preset) {
        var range = getPresetRange(preset);
        setRange(range);
        if (rangeCompare) {
            var rangeCompare_1 = {
                from: new Date(range.from.getFullYear() - 1, range.from.getMonth(), range.from.getDate()),
                to: range.to ? new Date(range.to.getFullYear() - 1, range.to.getMonth(), range.to.getDate()) : undefined,
            };
            setRangeCompare(rangeCompare_1);
        }
    };
    var checkPreset = function () {
        var _a, _b, _c, _d;
        for (var _i = 0, PRESETS_1 = PRESETS; _i < PRESETS_1.length; _i++) {
            var preset = PRESETS_1[_i];
            var presetRange = getPresetRange(preset.name);
            var normalizedRangeFrom = new Date(range.from.setHours(0, 0, 0, 0));
            var normalizedPresetFrom = new Date(presetRange.from.setHours(0, 0, 0, 0));
            var normalizedRangeTo = new Date((_b = (_a = range.to) === null || _a === void 0 ? void 0 : _a.setHours(0, 0, 0, 0)) !== null && _b !== void 0 ? _b : 0);
            var normalizedPresetTo = new Date((_d = (_c = presetRange.to) === null || _c === void 0 ? void 0 : _c.setHours(0, 0, 0, 0)) !== null && _d !== void 0 ? _d : 0);
            if (normalizedRangeFrom.getTime() === normalizedPresetFrom.getTime() &&
                normalizedRangeTo.getTime() === normalizedPresetTo.getTime()) {
                setSelectedPreset(preset.name);
                return;
            }
        }
        setSelectedPreset(undefined);
    };
    var resetValues = function () {
        setRange({
            from: typeof initialDateFrom === 'string' ? new Date(initialDateFrom) : initialDateFrom,
            to: initialDateTo
                ? typeof initialDateTo === 'string'
                    ? new Date(initialDateTo)
                    : initialDateTo
                : typeof initialDateFrom === 'string'
                    ? new Date(initialDateFrom)
                    : initialDateFrom,
        });
        setRangeCompare(initialCompareFrom
            ? {
                from: typeof initialCompareFrom === 'string' ? new Date(initialCompareFrom) : initialCompareFrom,
                to: initialCompareTo
                    ? typeof initialCompareTo === 'string'
                        ? new Date(initialCompareTo)
                        : initialCompareTo
                    : typeof initialCompareFrom === 'string'
                        ? new Date(initialCompareFrom)
                        : initialCompareFrom,
            }
            : undefined);
    };
    (0, react_1.useEffect)(function () {
        checkPreset();
    }, [range]);
    // Helper function to check if two date ranges are equal
    var areRangesEqual = function (a, b) {
        if (!a || !b)
            return a === b; // If either is undefined, return true if both are undefined
        return a.from.getTime() === b.from.getTime() && (!a.to || !b.to || a.to.getTime() === b.to.getTime());
    };
    (0, react_1.useEffect)(function () {
        if (isOpen) {
            openedRangeRef.current = range;
            openedRangeCompareRef.current = rangeCompare;
        }
    }, [isOpen]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", { className: 'tw-flex tw-py-2', children: (0, jsx_runtime_1.jsx)("div", { className: 'tw-flex', children: (0, jsx_runtime_1.jsx)("div", { className: 'tw-flex tw-flex-col', children: (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(calendar_1.Calendar, { mode: 'range', onSelect: function (value) {
                                    if ((value === null || value === void 0 ? void 0 : value.from) != null) {
                                        setRange({ from: value.from, to: value === null || value === void 0 ? void 0 : value.to });
                                    }
                                }, selected: range, numberOfMonths: isSmallScreen ? 1 : 2, defaultMonth: new Date(new Date().setMonth(new Date().getMonth() - (isSmallScreen ? 0 : 1))) }) }) }) }) }), (0, jsx_runtime_1.jsxs)("div", { className: 'flex justify-end gap-2 py-2 pr-4', children: [(0, jsx_runtime_1.jsx)(button_1.Button, { onClick: function () {
                            setIsOpen(false);
                            resetValues();
                        }, variant: 'ghost', children: "Cancel" }), (0, jsx_runtime_1.jsx)(button_1.Button, { onClick: function () {
                            setIsOpen(false);
                            if (!areRangesEqual(range, openedRangeRef.current) ||
                                !areRangesEqual(rangeCompare, openedRangeCompareRef.current)) {
                                onUpdate === null || onUpdate === void 0 ? void 0 : onUpdate({ range: range, rangeCompare: rangeCompare });
                            }
                        }, children: "Update" })] })] }));
};
exports.DateRangePicker = DateRangePicker;
exports.DateRangePicker.displayName = 'DateRangePicker';
