"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROFILE_SETTING_NAVIGATIONS = void 0;
var PageEndpoints_1 = require("./PageEndpoints");
exports.PROFILE_SETTING_NAVIGATIONS = [
    {
        text: 'My Profile',
        link: PageEndpoints_1.PageEndpoints.account.myProfile,
    },
    {
        text: 'Social Media links',
        link: PageEndpoints_1.PageEndpoints.account.socialMediaLinks,
    },
    {
        text: 'Change Password',
        link: PageEndpoints_1.PageEndpoints.account.changePassword,
    },
    {
        text: 'Payment',
        link: PageEndpoints_1.PageEndpoints.account.payment,
    },
    {
        text: 'Privacy & Safety',
        link: PageEndpoints_1.PageEndpoints.account.privacySafety,
    },
    {
        text: 'Notifications',
        link: PageEndpoints_1.PageEndpoints.account.notificationSettings,
    },
];
