"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
/* IMPORT CUSTOM MODULES */
var context_1 = require("@/providers/context");
// ✔ create resizable's bar component
/**
 * #### Resizable's Bar Component ###
 * @description This component can render bar of the resizable component.
 * @returns { React.ReactNode } Resizable's bar component
 *
 * @example
 * ```typescriptreact
 * import { ResizableBar } from '@/components/molecules`
 * <ResizableBar />
 * ```
 *
 * @example
 * ```typescriptreact
 * import { Resizable } from '@/components/molecules`
 * <Resizable.Bar />
 * ```
 *
 * @version 1.0.0
 * @since 10.3.0
 * @author Wunna
 */
function ResizableBar() {
    var mouseDownHandler = (0, context_1.useResizableContext)().mouseDownHandler;
    return (0, jsx_runtime_1.jsx)("div", { className: 'tw-absolute tw-right-0 tw-h-full tw-w-2 tw-cursor-col-resize', onMouseDown: mouseDownHandler });
}
exports.default = ResizableBar;
