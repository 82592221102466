"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRerender = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
// @ts-ignore
window.rerender = function (target) {
    var _a;
    if (!target) {
        // @ts-ignore
        (_a = window.setRenderCountFunctions) === null || _a === void 0 ? void 0 : _a.forEach(function (func) { return func(function (prev) { return prev + 1; }); });
        // @ts-ignore
    }
    else if (window.setRenderCountFunctions[target]) {
        // @ts-ignore
        window.setRenderCountFunctions[target](function (prev) { return prev + 1; });
    }
};
var RerenderContext = (0, react_1.createContext)(1);
var useRerender = function () {
    var context = (0, react_1.useContext)(RerenderContext);
    if (!context)
        throw new Error('useRerender must be used within a rerender helper');
    return context;
};
exports.useRerender = useRerender;
var RerenderProvider = function (_a) {
    var rerenderCount = _a.rerenderCount, setRerenderCount = _a.setRerenderCount, children = _a.children, name = _a.name;
    (0, react_1.useEffect)(function () {
        // @ts-ignore
        if (window.setRenderCountFunctions)
            window.setRenderCountFunctions.push(setRerenderCount);
        // @ts-ignore
        else
            window.setRenderCountFunctions = [setRerenderCount];
        // @ts-ignore
        if (!window.setRenderCountFunctions[name])
            window.setRenderCountFunctions[name] = setRerenderCount;
        return function () {
            // @ts-ignore
            window.setRenderCountFunctions = window.setRenderCountFunctions.filter(function (func) { return func !== setRerenderCount; });
            // @ts-ignore
            if (window.setRenderCountFunctions[name])
                delete window.setRenderCountFunctions[name];
        };
    }, []);
    return (0, jsx_runtime_1.jsx)(RerenderContext.Provider, { value: rerenderCount, children: children });
};
/** ### Rerender Helper ###
 * @description This function can wrap any react component and can trigger rerender process.
 * @param Component - What component do you want to wrap?
 * @returns New component that already wrapped with rerender state.
 * @example
 * ```js
 * // React component angularize
 * angularize(rerenderHelper(HomePage), 'home-page', window.angular.module('live-trader'), {})
 *
 * // rerender trigger from angular
 * window.rerender(); // react component will rerender and get new value from $scope
 * ```
 */
function rerenderHelper(Component, name) {
    function ComponentWithRerenderHelper(props) {
        var _a = (0, react_1.useState)(1), rerenderCount = _a[0], setRerenderCount = _a[1];
        return ((0, jsx_runtime_1.jsx)(RerenderProvider, { rerenderCount: rerenderCount, setRerenderCount: setRerenderCount, name: name, children: (0, jsx_runtime_1.jsx)(Component, __assign({}, props)) }));
    }
    Object.defineProperty(ComponentWithRerenderHelper, 'name', { writable: true, value: Component.name });
    return ComponentWithRerenderHelper;
}
exports.default = rerenderHelper;
