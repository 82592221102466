"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var chart_js_1 = require("chart.js");
var react_chartjs_2_1 = require("react-chartjs-2");
chart_js_1.Chart.register(chart_js_1.CategoryScale, chart_js_1.LinearScale, chart_js_1.PointElement, chart_js_1.LineElement, chart_js_1.Title, chart_js_1.Tooltip, chart_js_1.Legend, chart_js_1.Filler);
/**
 * Line Chart Component
 */
function LineChart() {
    var _a, _b;
    var summaryRef = (0, react_1.useRef)();
    var legendItem = (_b = (_a = summaryRef === null || summaryRef === void 0 ? void 0 : summaryRef.current) === null || _a === void 0 ? void 0 : _a.legend) === null || _b === void 0 ? void 0 : _b.legendItems[0];
    var scores = [6, 5, 5, 5, 3, 4, 6, 4, 5];
    // const scores2 = [1, 3, 2, 2, 4, 4, 5, 3, 2]
    var labels = [100, 200, 300, 400, 500, 600, 700];
    var data = {
        datasets: [
            {
                label: 'Sentiment Score',
                data: scores,
                tension: 0.3,
                borderColor: '#84A7D0',
                pointRadius: 6,
                pointBackgroundColor: '#84A7D0',
                backgroundColor: 'transparent',
            },
            // {
            //   label: 'Mis datos (2)',
            //   tension: 0.3,
            //   data: scores2,
            //   borderColor: 'green',
            //   backgroundColor: 'rgba(0, 255, 0, 0.3)',
            //   pointRadius: 6,
            // },
        ],
        labels: labels,
    };
    var options = {
        fill: true,
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Sentiment Score',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };
    return ((0, jsx_runtime_1.jsxs)("section", { className: 'flex-col tw-flex tw-w-full tw-items-start tw-space-y-4', children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-1', children: [(0, jsx_runtime_1.jsx)("div", { className: 'tw-h-[8px] tw-w-[8px] tw-rounded-full', style: {
                            backgroundColor: legendItem === null || legendItem === void 0 ? void 0 : legendItem.strokeStyle,
                        } }), (0, jsx_runtime_1.jsx)("span", { className: 'tw-text-xs tw-font-normal tw-text-text-4', children: legendItem === null || legendItem === void 0 ? void 0 : legendItem.text })] }), (0, jsx_runtime_1.jsx)(react_chartjs_2_1.Line, { data: data, options: options, ref: summaryRef })] }));
}
exports.default = LineChart;
