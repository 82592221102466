"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
// ✔ create user's level component
/**
 * #### User's Level Component ####
 * @description This component can render user's level.
 * @param { UserLevelProps } props - What is user's level component property?
 * @returns { React.ReactNode } User's level component
 *
 * @example
 * ```typescriptreact
 * import { UserLevel } from '@/components/molecules`
 * <UserLevel {...USER_LEVEL} />
 * ```
 * @example
 * ```typescriptreact
 * import { User } from '@/components/molecules`
 * <User.Level {...USER_LEVEL} />
 * ```
 *
 * @version 1.0.0
 * @since 10.3.0
 * @author Wunna
 */
function UserLevel(props) {
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-1 tw-rounded-full tw-bg-background-6 tw-px-3 tw-py-1 tw-text-xs group-hover:tw-bg-background-4', children: ["Lvl ", props.number, " ", (0, jsx_runtime_1.jsx)("img", { className: 'tw-h-3 tw-w-3', src: props.image })] }));
}
exports.default = UserLevel;
