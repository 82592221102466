"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var switch_1 = require("@/components/ui/switch");
var translations_helper_1 = require("@/helpers/translations-helper");
var utils_1 = require("@/lib/utils");
var ProfileSection = function (_a) {
    var enable = _a.enable, setEnable = _a.setEnable, switchDisabled = _a.switchDisabled;
    var translations = (0, translations_helper_1.useTranslations)().translations;
    return ((0, jsx_runtime_1.jsxs)("div", { className: (0, utils_1.cn)('tw-flex tw-items-center tw-justify-between tw-transition-all tw-duration-200 tw-ease-in-out', !switchDisabled ? 'tw-opacity-100' : '!tw-cursor-not-allowed !tw-opacity-30'), children: [(0, jsx_runtime_1.jsx)("p", { className: (0, utils_1.cn)('tw-text-[14px] tw-font-[400]', switchDisabled ? 'tw-text-text-1' : enable ? 'tw-text-text-1' : 'tw-text-text-4'), children: translations.Advanced_Referral_Tracking.profile_tracking_label }), (0, jsx_runtime_1.jsx)(switch_1.Switch, { size: 'small', checked: enable, onCheckedChange: setEnable, disabled: switchDisabled, className: (0, utils_1.cn)(switchDisabled ? 'tw-opacity-100' : enable ? '!tw-opacity-100' : '!tw-opacity-80') })] }));
};
exports.default = ProfileSection;
