"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Textarea = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var General_1 = require("@/components/molecules/icons/General");
var utils_1 = require("@/lib/utils");
var React = __importStar(require("react"));
var Textarea = React.forwardRef(function (_a, ref) {
    var _b;
    var className = _a.className, name = _a.name, label = _a.label, labelClassName = _a.labelClassName, error = _a.error, success = _a.success, value = _a.value, maxLength = _a.maxLength, showCount = _a.showCount, props = __rest(_a, ["className", "name", "label", "labelClassName", "error", "success", "value", "maxLength", "showCount"]);
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-relative tw-flex tw-w-full tw-flex-col tw-items-start tw-transition-all tw-duration-300 tw-ease-in-out', children: [(0, jsx_runtime_1.jsx)("label", { className: (0, utils_1.cn)('tw-text-sm tw-font-[500] tw-text-text-1', labelClassName), htmlFor: name, children: label }), (0, jsx_runtime_1.jsx)("textarea", __assign({ className: (0, utils_1.cn)('tw-mt-2 tw-flex tw-min-h-[100px] tw-w-full tw-resize-none tw-rounded-md tw-border tw-border-background-6 tw-bg-background-6 tw-px-3 tw-py-2 tw-text-sm tw-text-text-1 placeholder:tw-text-customgrey hover:tw-border-accent-1 focus-visible:tw-border focus-visible:tw-border-accent-1 focus-visible:tw-outline-none focus-visible:tw-ring-0 disabled:tw-cursor-not-allowed disabled:tw-opacity-50', className, error ? 'tw-border tw-border-error-1 hover:tw-border-error-2 focus-visible:tw-border-error-1' : '', success ? 'tw-border tw-border-success-1 hover:tw-border-r-error-2 focus-visible:tw-border-success-1' : ''), ref: ref, value: value, name: name, maxLength: maxLength }, props)), error && ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-mt-1 tw-flex tw-items-center tw-gap-1', children: [(0, jsx_runtime_1.jsx)(General_1.CircleErrorSVG, {}), (0, jsx_runtime_1.jsx)("span", { className: 'tw-text-xs tw-text-error-1', children: error })] })), success && ((0, jsx_runtime_1.jsxs)("div", { className: 'ftw-lex tw-mt-1 tw-items-center tw-gap-1', children: [(0, jsx_runtime_1.jsx)(General_1.CircleSuccessSVG, {}), (0, jsx_runtime_1.jsx)("span", { className: 'tw-text-xs tw-text-success-1', children: success })] })), showCount ? ((0, jsx_runtime_1.jsxs)("span", { className: 'tw-mt-2 tw-w-full tw-text-right tw-text-xs', children: [(_b = value === null || value === void 0 ? void 0 : value.toString()) === null || _b === void 0 ? void 0 : _b.length, "/", maxLength] })) : null] }));
});
exports.Textarea = Textarea;
Textarea.displayName = 'Textarea';
