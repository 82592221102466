"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var General_1 = require("@/components/molecules/icons/General");
var react_dropzone_1 = require("react-dropzone");
var XLSUpload = function (_a) {
    var onDrop = _a.onDrop;
    var _b = (0, react_dropzone_1.useDropzone)({
        accept: {
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'text/csv': ['.csv'],
        },
        onDrop: onDrop,
    }), getRootProps = _b.getRootProps, getInputProps = _b.getInputProps, isDragActive = _b.isDragActive;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({}, getRootProps(), { className: 'tw-w-full', children: [(0, jsx_runtime_1.jsx)("input", __assign({}, getInputProps())), isDragActive ? ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex !tw-h-fit tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-border-2 tw-border-dashed tw-border-text-4 tw-p-4', children: [(0, jsx_runtime_1.jsx)(General_1.UploadXLSSVG, {}), (0, jsx_runtime_1.jsx)("h5", { className: 'tw-text-text-4', children: "Drop the .xls file here" }), (0, jsx_runtime_1.jsx)("p", { className: 'tw-text-text-4', children: "(Maximum file size: 10MB)" })] })) : ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex !tw-h-fit tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-border-2 tw-border-dashed tw-border-text-4 tw-p-4', children: [(0, jsx_runtime_1.jsx)(General_1.UploadXLSSVG, {}), (0, jsx_runtime_1.jsx)("h5", { className: 'tw-text-text-4', children: "Upload your files here" }), (0, jsx_runtime_1.jsx)("p", { className: 'tw-text-text-4', children: "(Maximum file size: 10MB)" })] }))] })));
};
exports.default = XLSUpload;
