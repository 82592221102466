"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserTransactionsState = exports.StoreFrontActivetab = exports.StoreFrontDetailState = exports.ViewAllRewardsState = exports.SelectedRewardState = exports.CategoryChangeInRewardState = exports.StripeProductsState = exports.SelectedProductState = exports.SelectedVouchersState = exports.SelectedCategoriesState = exports.SelectedRewardsState = void 0;
var recoil_1 = require("recoil");
/** Selected Rewards Ids State for check/uncheck */
exports.SelectedRewardsState = (0, recoil_1.atom)({
    key: 'SelectedRewardsState',
    default: [],
});
/** Selected Categories Ids State for check/uncheck */
exports.SelectedCategoriesState = (0, recoil_1.atom)({
    key: 'SelectedCategoriesState',
    default: [],
});
/** Selected Vouchers Ids State for check/uncheck */
exports.SelectedVouchersState = (0, recoil_1.atom)({
    key: 'SelectedVouchersState',
    default: [],
});
/** Selected Stripe Product Id State */
exports.SelectedProductState = (0, recoil_1.atom)({
    key: 'SelectedProductState',
    default: null,
});
/** Stripe Products Array */
exports.StripeProductsState = (0, recoil_1.atom)({
    key: 'StripeProductsState',
    default: [],
});
/** Select Category Change State in Reward Customisation Column
 * @description this state is created to pass the state to `rewards-column`
 */
exports.CategoryChangeInRewardState = (0, recoil_1.atom)({
    key: 'CategoryChangeInRewardState',
    default: '',
});
/** Select Voucher Change State in Reward Customisation Column
 * @description this state is created to pass the state to `rewards-column`
 */
exports.SelectedRewardState = (0, recoil_1.atom)({
    key: 'SelectedRewardState',
    default: null,
});
/** View All Rewards State for Store Front */
exports.ViewAllRewardsState = (0, recoil_1.atom)({
    key: 'ViewAllRewardsState',
    default: false,
});
/** Store Details State
 * @description this state is created to display all rewards in the store
 */
exports.StoreFrontDetailState = (0, recoil_1.atom)({
    key: 'StoreDetailState',
    default: {
        category: null,
        categoryId: null,
        banner: null,
        rewards: [],
    },
});
/** Store Front Active Tab */
exports.StoreFrontActivetab = (0, recoil_1.atom)({
    key: 'StoreFrontActivetab',
    default: 'Store',
});
/** User Transactions State */
exports.UserTransactionsState = (0, recoil_1.atom)({
    key: 'UserTransactionsState',
    default: [],
});
