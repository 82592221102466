"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var checkbox_1 = require("@/components/ui/checkbox");
var UserCheckbox = function (_a) {
    var id = _a.id, user = _a.user, onUserClick = _a.onUserClick, props = __rest(_a, ["id", "user", "onUserClick"]);
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-space-x-2', children: [(0, jsx_runtime_1.jsx)(checkbox_1.Checkbox, __assign({ id: id }, props, { className: '!tw-border-divider-lines-1' })), (0, jsx_runtime_1.jsx)("div", { "aria-hidden": 'true', className: 'tw-flex tw-cursor-pointer tw-justify-between', onClick: function () { return onUserClick(user); }, children: (0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-2', children: [(0, jsx_runtime_1.jsx)("img", { src: user.user_image, width: 50, height: 50, className: 'tw-h-10 tw-w-10 tw-rounded-full tw-object-cover', alt: user.displayname || user.nicename }), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-flex-col', children: [(0, jsx_runtime_1.jsx)("div", { className: 'tw-flex tw-gap-3', children: (0, jsx_runtime_1.jsx)("h4", { className: 'tw-text-sm tw-font-bold tw-text-text-4', children: user.displayname || user.nicename }) }), (0, jsx_runtime_1.jsxs)("h5", { className: 'tw-mt-[2px] tw-text-sm tw-text-text-4', children: ["@", user.nicename] })] })] }) })] }));
};
exports.default = UserCheckbox;
