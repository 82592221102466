"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var chart_js_1 = require("chart.js");
var react_1 = require("react");
var react_chartjs_2_1 = require("react-chartjs-2");
chart_js_1.Chart.register(chart_js_1.CategoryScale, chart_js_1.LinearScale, chart_js_1.PointElement, chart_js_1.BarElement, chart_js_1.Title, chart_js_1.Tooltip, chart_js_1.Legend, chart_js_1.Filler);
/**
 * BarChart Component
 */
function BarChart() {
    var _a, _b;
    var summaryRef = (0, react_1.useRef)();
    var legendItem = (_b = (_a = summaryRef === null || summaryRef === void 0 ? void 0 : summaryRef.current) === null || _a === void 0 ? void 0 : _a.legend) === null || _b === void 0 ? void 0 : _b.legendItems[0];
    var scores = [6, 5, 5, 5, 3, 4, 6, 4, 5];
    var labels = ['21/10', '22/10', '23/10', '24/10', '25/10', '26/10', '27/10'];
    var data = {
        labels: labels,
        datasets: [
            {
                label: 'New Member',
                borderColor: '#84A7D0',
                backgroundColor: '#84A7D0',
                data: scores,
            },
        ],
    };
    var options = {
        fill: true,
        animations: true,
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'New Member',
                },
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
    };
    return ((0, jsx_runtime_1.jsxs)("section", { className: 'tw-flex tw-w-full tw-flex-col tw-items-start tw-space-y-4', children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-1', children: [(0, jsx_runtime_1.jsx)("div", { className: 'tw-h-[8px] tw-w-[8px] tw-rounded-full', style: {
                            backgroundColor: legendItem === null || legendItem === void 0 ? void 0 : legendItem.fillStyle,
                        } }), (0, jsx_runtime_1.jsx)("span", { className: 'tw-text-xs tw-font-normal tw-text-text-4', children: legendItem === null || legendItem === void 0 ? void 0 : legendItem.text })] }), (0, jsx_runtime_1.jsx)(react_chartjs_2_1.Bar, { data: data, options: options, ref: summaryRef })] }));
}
exports.default = BarChart;
