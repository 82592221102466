"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGenerateParagonToken = exports.useInfiniteMentionUserList = void 0;
var useQuery_1 = require("./useQuery");
/**
 * ### Mention User List with pagination
 * @param channelId - Selected Channel Id
 * @param userId - LoggedIn User Id
 * @param keyword - Mention Keyword
 * @param page - Current Page
 * @param rowPerPage - Rows per page
 */
var useInfiniteMentionUserList = function (channelId, userId) {
    var _a, _b, _c;
    var _d = (0, useQuery_1.useFetchQuery)("/server/".concat(channelId, "/").concat(userId, "/mention-user-list"), {
        service: 'BASE',
        method: 'GET',
        key: ["/server/".concat(channelId, "/").concat(userId, "/mention-user-list")],
    }, {
        staleTime: Infinity,
        keepPreviousData: true,
    }), data = _d.data, isLoading = _d.isLoading, isFetching = _d.isFetching, isError = _d.isError, refetch = _d.refetch;
    var users = (_a = data === null || data === void 0 ? void 0 : data.data.data) === null || _a === void 0 ? void 0 : _a.users;
    var roles = (_b = data === null || data === void 0 ? void 0 : data.data.data) === null || _b === void 0 ? void 0 : _b.roles.map(function (data) { return ({
        id: data._id,
        name: "@".concat(data.title.replace('@', '')),
        customcolor: "var(--".concat(data.class, ")"),
        type: 'role',
        serverId: data.serverId,
        $$hashKey: '',
    }); });
    return {
        users: users,
        roles: roles,
        totalPageData: (_c = data === null || data === void 0 ? void 0 : data.data.data) === null || _c === void 0 ? void 0 : _c.totalPage,
        isLoading: isLoading,
        isFetching: isFetching,
        isError: isError,
        refetch: refetch,
    };
};
exports.useInfiniteMentionUserList = useInfiniteMentionUserList;
var useGenerateParagonToken = function (userId) {
    var _a = (0, useQuery_1.useFetchQuery)("/server/generate-paragon-token", {
        service: 'BASE',
        method: 'POST',
        payload: {
            userId: userId,
        },
    }), data = _a.data, isLoading = _a.isLoading, isError = _a.isError;
    return {
        data: data,
        isLoading: isLoading,
        isError: isError,
    };
};
exports.useGenerateParagonToken = useGenerateParagonToken;
