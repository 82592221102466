"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STATISTIC_CARDS = exports.DASHBOARD_DEMOS = void 0;
/** Dashboard Mock Data */
exports.DASHBOARD_DEMOS = [
    {
        id: '001',
        name: 'Overview',
    },
    {
        id: '002',
        name: 'Members',
    },
    {
        id: '003',
        name: 'Activity',
    },
];
/** Statistic Cards Mock Data */
exports.STATISTIC_CARDS = [
    {
        id: '001',
        title: 'Total Members',
        count: 196,
        percent: 20,
    },
    {
        id: '002',
        title: 'Message Sent',
        count: 1960,
        percent: 20,
    },
    {
        id: '003',
        title: 'Engagement Score',
        count: 80,
        percent: 20,
    },
    {
        id: '004',
        title: 'Sentiment Score',
        count: 0.8,
        percent: 20,
        showTooltip: true,
    },
];
