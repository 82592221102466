"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var CardExpiryInput_1 = require("@/components/atoms/Inputs/CreditCardInputs/CardExpiryInput");
var CardNumberInput_1 = require("@/components/atoms/Inputs/CreditCardInputs/CardNumberInput");
var Input_1 = require("@/components/atoms/Inputs/Input");
var button_1 = require("@/components/ui/button");
var dialog_1 = require("@/components/ui/dialog");
var PaymentForm_1 = require("@/dto/schemas/account/PaymentForm");
var formik_1 = require("formik");
function AddPaymentModal(_a) {
    var open = _a.open, onClose = _a.onClose;
    var handleSave = function (values) {
        console.log('values', values);
    };
    return ((0, jsx_runtime_1.jsx)(dialog_1.Dialog, { open: open, onOpenChange: onClose, children: (0, jsx_runtime_1.jsxs)(dialog_1.DialogContent, { className: 'tw-max-w-xl tw-overflow-hidden tw-p-0', children: [(0, jsx_runtime_1.jsx)(dialog_1.DialogHeader, { className: 'tw-mb-0 tw-border-b-[0.5px] tw-border-text-4 tw-px-6 tw-py-6', children: (0, jsx_runtime_1.jsx)(dialog_1.DialogTitle, { className: 'tw-ttw-ext-center tw-text-lg', children: "Add card" }) }), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-px-5 tw-pb-6 tw-pt-2', children: [(0, jsx_runtime_1.jsx)("h2", { className: 'tw-text-base tw-font-bold', children: "Card Details" }), (0, jsx_runtime_1.jsx)(formik_1.Formik, { validateOnBlur: true, validateOnChange: true, initialValues: PaymentForm_1.defaultPaymentForm, validationSchema: PaymentForm_1.paymentFormValidationSchema, onSubmit: handleSave, children: function (_a) {
                                var handleSubmit = _a.handleSubmit, values = _a.values, touched = _a.touched, handleChange = _a.handleChange, resetForm = _a.resetForm, setFieldValue = _a.setFieldValue, errors = _a.errors;
                                var saveDisabled = Object.keys(errors).length > 0;
                                return ((0, jsx_runtime_1.jsxs)(formik_1.Form, { onSubmit: handleSubmit, children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-mt-6 tw-flex tw-flex-col tw-items-start tw-gap-6', children: [(0, jsx_runtime_1.jsx)(Input_1.Input, { name: 'name', value: values.name, onChange: handleChange, error: touched.name ? errors.name : '', label: 'Name', placeholder: 'Name on Card' }), (0, jsx_runtime_1.jsx)(CardNumberInput_1.CardNumberInput, { name: 'cardNumber', value: values.cardNumber, onValueChange: function (val) { return setFieldValue('cardNumber', val); }, error: touched.cardNumber ? errors.cardNumber : '', label: 'Card Number', placeholder: '**** **** **** ****' }), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-grid tw-w-full tw-grid-cols-2 tw-gap-5', children: [(0, jsx_runtime_1.jsx)(CardExpiryInput_1.CardExpiryInput, { name: 'expiry', value: values.expiry, onValueChange: function (val) { return setFieldValue('expiry', val); }, error: touched.expiry ? values.expiry : '', label: 'Expiry', placeholder: 'MM / YY' }), (0, jsx_runtime_1.jsx)(Input_1.Input, { name: 'CVC', value: values.CVC, onChange: handleChange, error: touched.CVC ? errors.CVC : '', label: 'CVC', placeholder: 'CVC', maxLength: 3 })] }), (0, jsx_runtime_1.jsx)(Input_1.Input, { name: 'address', value: values.address, onChange: handleChange, error: touched.address ? errors.address : '', label: 'Address', placeholder: 'Address' }), (0, jsx_runtime_1.jsx)(Input_1.Input, { name: 'postalCode', value: values.postalCode, onChange: handleChange, error: touched.postalCode ? errors.postalCode : '', label: 'Postal Code', placeholder: 'Postal Code' })] }), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-mt-8 tw-flex tw-items-end tw-justify-end tw-gap-3', children: [(0, jsx_runtime_1.jsx)(button_1.Button, { variant: 'link', size: 'sm', onClick: function () {
                                                        resetForm();
                                                        onClose();
                                                    }, children: "Cancel" }), (0, jsx_runtime_1.jsx)(button_1.Button, { type: 'submit', disabled: saveDisabled, variant: 'success', size: 'sm', children: "Submit" })] })] }));
                            } })] })] }) }));
}
exports.default = AddPaymentModal;
