"use strict";
'use client';
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccordionItemContext = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var Accordion_1 = require("./Accordion");
exports.AccordionItemContext = (0, react_1.createContext)({
    accordionRef: null,
    active: false,
    items: {},
    hash: '',
    transition: null,
    alwaysOpen: false,
    toggle: function () { },
    isActive: false,
});
var AccordionItem = function (_a) {
    var children = _a.children, _b = _a.isActive, isActive = _b === void 0 ? false : _b;
    var _c = (0, react_1.useContext)(Accordion_1.AccordionContext), accordionRef = _c.accordionRef, items = _c.items, setItems = _c.setItems, transition = _c.transition, alwaysOpen = _c.alwaysOpen;
    var _d = (0, react_1.useState)(false), active = _d[0], setActive = _d[1];
    var hash = (0, react_1.useMemo)(function () {
        return Math.random().toString(36).substring(2, 9);
    }, []);
    (0, react_1.useEffect)(function () {
        var _a;
        if (!(hash in items)) {
            setItems(__assign(__assign({}, items), (_a = {}, _a[hash] = setActive, _a)));
        }
    }, [hash, items, setItems]);
    var value = (0, react_1.useMemo)(function () {
        return {
            accordionRef: accordionRef,
            active: active,
            toggle: function () { return setActive(!active); },
            items: items,
            hash: hash,
            transition: transition,
            alwaysOpen: alwaysOpen,
            isActive: isActive,
        };
    }, [accordionRef, active, alwaysOpen, hash, isActive, items, transition]);
    return ((0, jsx_runtime_1.jsx)(exports.AccordionItemContext.Provider, { value: value, children: typeof children === 'function' ? children({ open: active }) : children }));
};
exports.default = AccordionItem;
