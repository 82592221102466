"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.columns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var General_1 = require("@/components/molecules/icons/General");
var utils_1 = require("@/lib/utils");
/**
 * Rewards Management Table Columns
 */
exports.columns = [
    {
        accessorKey: 'orderId',
        enableSorting: true,
        header: 'Order ID',
        cell: function (_a) {
            var row = _a.row;
            return row.getValue('orderId') || '-';
        },
    },
    {
        accessorKey: 'reward',
        enableSorting: true,
        header: 'Reward',
        cell: function (_a) {
            var row = _a.row;
            var reward = row.getValue('reward');
            return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-w-[650px] tw-items-center tw-gap-2', children: [reward.image ? ((0, jsx_runtime_1.jsx)("img", { src: reward.image, className: 'tw-h-[40px] tw-w-[60px] tw-rounded-md' })) : ((0, jsx_runtime_1.jsx)("div", { className: 'tw-flex tw-h-[40px] tw-w-[60px] tw-items-center tw-justify-center tw-rounded-[8px] tw-border tw-border-divider-lines-1 tw-bg-background-3 tw-object-cover', children: (0, jsx_runtime_1.jsx)(General_1.RewardItemPlaceholderSVG, {}) })), (0, jsx_runtime_1.jsx)("span", { children: reward.name })] }));
        },
    },
    {
        accessorKey: 'price',
        enableSorting: true,
        header: 'Price',
        cell: function (_a) {
            var _b, _c, _d;
            var row = _a.row;
            var icon = (_b = row.getValue('price')) === null || _b === void 0 ? void 0 : _b.icon;
            var price = (_c = row.getValue('price')) === null || _c === void 0 ? void 0 : _c.price;
            var unit = (_d = row.getValue('price')) === null || _d === void 0 ? void 0 : _d.unit;
            return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-2', children: [(0, jsx_runtime_1.jsx)("img", { src: icon, className: 'tw-h-[16px] tw-w-[16px] tw-rounded-full tw-object-cover' }), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-space-x-1', children: [(0, jsx_runtime_1.jsx)("span", { children: price || 0 }), (0, jsx_runtime_1.jsx)("span", { children: unit || '---' })] })] }));
        },
    },
    {
        accessorKey: 'quantity',
        enableSorting: true,
        header: 'Quantity',
        cell: function (_a) {
            var row = _a.row;
            return row.getValue('quantity') || '-';
        },
    },
    {
        accessorKey: 'purchasedDate',
        enableSorting: true,
        header: 'Date of Purchase',
        cell: function (_a) {
            var row = _a.row;
            var purchasedDate = row.getValue('purchasedDate');
            if (purchasedDate) {
                return (0, jsx_runtime_1.jsx)("span", { children: (0, utils_1.formatDate)(purchasedDate) });
            }
            else {
                return (0, jsx_runtime_1.jsx)("span", { children: "-" });
            }
        },
    },
    {
        accessorKey: 'voucherCode',
        enableSorting: true,
        header: 'Voucher Code',
        cell: function (_a) {
            var row = _a.row;
            return row.getValue('voucherCode');
        },
    },
    {
        accessorKey: 'voucherExpiryDate',
        enableSorting: true,
        header: 'Voucher Expiry Date',
        cell: function (_a) {
            var row = _a.row;
            var voucherExpiryDate = row.getValue('voucherExpiryDate');
            if (voucherExpiryDate) {
                return (0, jsx_runtime_1.jsx)("span", { children: (0, utils_1.formatDate)(voucherExpiryDate) });
            }
            else {
                return (0, jsx_runtime_1.jsx)("span", { children: "-" });
            }
        },
    },
    {
        accessorKey: 'purchaseStatus',
        enableSorting: true,
        header: 'Status',
        cell: function (_a) {
            var row = _a.row;
            var status = row.getValue('purchaseStatus');
            if (status === 'completed') {
                return (0, jsx_runtime_1.jsx)(StatusBadge, { text: 'Completed', status: 'completed' });
            }
            else {
                return (0, jsx_runtime_1.jsx)(StatusBadge, { text: 'Transaction Error', status: 'transaction-error' });
            }
        },
    },
];
function StatusBadge(_a) {
    var text = _a.text, status = _a.status;
    return ((0, jsx_runtime_1.jsx)("div", { className: (0, utils_1.cn)('tw-w-fit tw-whitespace-nowrap tw-rounded-[4px] tw-px-[8px] tw-py-[4px] tw-text-[12px] tw-text-text-1', status === 'transaction-error' ? 'tw-bg-error-2' : 'tw-bg-success-1'), children: text }));
}
