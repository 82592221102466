"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.profileFormValidationSchema = exports.defaultProfileForm = void 0;
var yup = __importStar(require("yup"));
exports.defaultProfileForm = {
    userName: '',
    displayName: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    country: '',
    aboutMe: '',
};
exports.profileFormValidationSchema = yup.object().shape({
    userName: yup.string().required('User name is required'),
    displayName: yup.string().required('Display name is required'),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Inavalid email format').required('Email is required'),
    phoneNumber: yup.string().required('Phone number is required'),
    country: yup.string().required('Country is required'),
    aboutMe: yup.string().required('Yourself is required'),
});
