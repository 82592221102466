"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scrollToElementByIdWithOffset = exports.toggleBottomScrollBtn = exports.scrollToElement = exports.deleteScrollFunctions = exports.triggerElement = exports.isElementInView = exports.scrollFunctions = void 0;
/** scroll functions with Ids */
exports.scrollFunctions = {};
/** check if element in view */
function isElementInView(container, element) {
    var containerRect = container.getBoundingClientRect();
    if (element) {
        var elementRect = element.getBoundingClientRect();
        var space = containerRect.height * 0.2;
        var isInView = elementRect.top + space >= containerRect.top && elementRect.bottom - space <= containerRect.bottom;
        return isInView;
    }
    else {
        return false;
    }
}
exports.isElementInView = isElementInView;
/** trigger element to add in scrollFunctions */
function triggerElement(id, callback) {
    // console.log('triggerElement id =>', id)
    exports.scrollFunctions[id] = callback;
}
exports.triggerElement = triggerElement;
/** delete all scroll functions */
function deleteScrollFunctions() {
    Object.keys(exports.scrollFunctions).forEach(function (key) {
        delete exports.scrollFunctions[key];
    });
}
exports.deleteScrollFunctions = deleteScrollFunctions;
/** scroll to element */
function scrollToElement(idOrElement, target) {
    if (target === void 0) { target = 'middle'; }
    var isMobile = window.innerWidth <= 767;
    if (typeof idOrElement === 'string') {
        var interval_1 = setInterval(function () {
            var element = document.getElementById("msg_".concat(idOrElement));
            if (!element)
                return;
            if (target === 'middle') {
                // console.log('✅ scroll back to (middle) ->', idOrElement)
                element.scrollIntoView({ behavior: 'instant', block: 'center' });
            }
            else if (target === 'top') {
                // console.log('✅ scroll back to (top) ->', element)
                if (isMobile) {
                    element.scrollIntoView({ behavior: 'auto', block: 'nearest' });
                }
                else {
                    element.scrollIntoView({ behavior: 'instant', block: 'center' });
                }
            }
            else {
                // console.log('✅ scroll back to (bottom) ->', element)
                element.scrollIntoView({ behavior: 'instant', block: 'end' });
            }
            clearInterval(interval_1);
        }, 100);
    }
    else {
        idOrElement.scrollTop = idOrElement.scrollHeight;
    }
}
exports.scrollToElement = scrollToElement;
/** toggle show hide `bottom-scroll-btn` */
function toggleBottomScrollBtn(element) {
    var bScrollFlag = element.scrollHeight - Math.round(element.scrollTop) - element.clientHeight <= 1;
    var button = document.getElementById('bottom-scroll-btn');
    if (button) {
        if (bScrollFlag) {
            button.style.display = 'none';
        }
        else {
            button.style.display = 'block';
        }
    }
}
exports.toggleBottomScrollBtn = toggleBottomScrollBtn;
/** scroll to last appended elem */
function scrollToElementByIdWithOffset(id, container) {
    var element = document.getElementById("msg_".concat(id));
    if (element && container) {
        var elementRect = element.getBoundingClientRect();
        var containerRect = container.getBoundingClientRect();
        var elementTopToContainer = elementRect.top - containerRect.top;
        var centerOffset = containerRect.height / 2 - elementRect.height / 2;
        var newScrollTop = container.scrollTop + elementTopToContainer - centerOffset;
        // console.log('✅ scroll back to -> ', element)
        container.scrollTo({
            top: newScrollTop,
            behavior: 'auto',
        });
    }
}
exports.scrollToElementByIdWithOffset = scrollToElementByIdWithOffset;
