"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var General_1 = require("@/components/molecules/icons/General");
var typo_utils_1 = require("@/lib/utils/typo-utils");
var IconMap = {
    twitter: (0, jsx_runtime_1.jsx)(General_1.TwitterSVG, {}),
    facebook: (0, jsx_runtime_1.jsx)(General_1.FacebookSVG, {}),
    instagram: (0, jsx_runtime_1.jsx)(General_1.InstagramSVG, {}),
    linkedin: (0, jsx_runtime_1.jsx)(General_1.LinkedinSVG, {}),
    telegram: (0, jsx_runtime_1.jsx)(General_1.TelegramSVG, {}),
    tiktok: (0, jsx_runtime_1.jsx)(General_1.TiktokSVG, {}),
    website: (0, jsx_runtime_1.jsx)(General_1.WebsiteSVG, {}),
    youtube: (0, jsx_runtime_1.jsx)(General_1.YoutubeSVG, {}),
};
var PrefixMap = {
    twitter: 'http://twitter.com/',
    facebook: 'http://facebook.com/',
    instagram: 'http://instagram.com/',
    linkedin: 'http://linkedin.com/',
    telegram: 'http://t.me/@',
    tiktok: 'http://tiktok.com/@',
    website: '',
    youtube: 'http://youtube.com/@',
    url: 'https://genesiv.com/app/',
};
function SocialMediaInput(_a) {
    var socialType = _a.socialType, label = _a.label, subLabel = _a.subLabel, props = __rest(_a, ["socialType", "label", "subLabel"]);
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-relative tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2 tw-transition-all tw-duration-300 tw-ease-in-out', children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-2', children: [IconMap[socialType], (0, jsx_runtime_1.jsx)("span", { className: 'tw-text-sm tw-font-bold tw-text-text-1', children: (0, typo_utils_1.capitalizeFirstLetter)(socialType) })] }), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-w-full tw-items-center tw-rounded-md tw-border tw-border-background-6 tw-bg-background-6 tw-px-4 tw-py-[10px] hover:tw-border hover:tw-border-accent-1 focus-visible:tw-border-accent-1', children: [(0, jsx_runtime_1.jsx)("p", { className: 'tw-mr-1 tw-text-sm tw-text-text-1', children: PrefixMap[socialType] }), (0, jsx_runtime_1.jsx)("input", __assign({ type: 'text', className: 'tw-flex-1 tw-bg-transparent tw-text-sm tw-text-text-1 tw-outline-none', "aria-label": socialType + ' social media input', spellCheck: false }, props))] })] }));
}
exports.default = SocialMediaInput;
