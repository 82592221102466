"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.buttonVariants = exports.Button = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_slot_1 = require("@radix-ui/react-slot");
var class_variance_authority_1 = require("class-variance-authority");
var React = __importStar(require("react"));
var utils_1 = require("../../lib/utils");
var buttonVariants = (0, class_variance_authority_1.cva)('tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-text-sm tw-transition-colors tw-ease-in-out tw-focus-visible:ring-1 focus-visible:tw-ring-offset-2 disabled:tw-cursor-not-allowed disabled:tw-opacity-50 tw-font-semibold', {
    variants: {
        variant: {
            default: 'tw-bg-accent-1 tw-text-background-1 hover:tw-opacity-80',
            outline: 'tw-border tw-border-accent-1 tw-bg-transparent hover:tw-bg-accent-1 tw-text-button-text-color hover:tw-text-text-1 hover:tw-border-accent-1',
            danger: 'tw-bg-error-2 hover:tw-bg-error-2/80 tw-text-button-text-color',
            success: 'tw-bg-success-2 hover:tw-bg-success-2/90',
            slate: 'tw-bg-background-7 hover:tw-opacity-80 tw-text-text-4',
            grey: 'tw-bg-background-6 hover:tw-opacity-80 tw-text-text-1',
            warn: 'tw-bg-customyellow hover:tw-bg-customyellow/90 tw-text-background-1',
            link: 'tw-text-text-1 focus-visible:tw-underline tw-underline-offset-4 hover:tw-underline',
            ghost: 'hover:tw-bg-accent-1 hover:tw-text-text-1',
        },
        size: {
            default: 'tw-h-10 tw-px-4 tw-py-2',
            sm: 'tw-h-8 tw-rounded-md tw-text-xs tw-px-3',
            lg: 'tw-h-11 tw-rounded-md tw-px-8',
        },
    },
    defaultVariants: {
        variant: 'default',
        size: 'default',
    },
});
exports.buttonVariants = buttonVariants;
var Button = React.forwardRef(function (_a, ref) {
    var className = _a.className, variant = _a.variant, size = _a.size, _b = _a.asChild, asChild = _b === void 0 ? false : _b, props = __rest(_a, ["className", "variant", "size", "asChild"]);
    var Comp = asChild ? react_slot_1.Slot : 'button';
    return (0, jsx_runtime_1.jsx)(Comp, __assign({ className: (0, utils_1.cn)(buttonVariants({ variant: variant, size: size, className: className })), ref: ref }, props));
});
exports.Button = Button;
Button.displayName = 'Button';
