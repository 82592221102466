"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
/**
 * Community Setting Header and Descriptions
 * @description This component is used for Displaying Description section in Community Setting pages
 * @example This component usage can be found at Community settings pages
 */
function CommunitySettingDescription(_a) {
    var title = _a.title, description = _a.description, onLearnMore = _a.onLearnMore, className = _a.className;
    return ((0, jsx_runtime_1.jsxs)("section", { className: className, children: [title && (0, jsx_runtime_1.jsx)("h1", { className: 'tw-text-base tw-font-bold tw-text-text-1', children: title }), (0, jsx_runtime_1.jsx)("hr", { className: 'tw-my-4 tw-w-full tw-border tw-border-divider-lines-1' }), (0, jsx_runtime_1.jsxs)("p", { className: 'tw-mt-1 tw-text-sm tw-text-text-4', children: [description, onLearnMore ? ((0, jsx_runtime_1.jsx)("span", { onClick: onLearnMore, "aria-hidden": 'true', className: 'tw-ml-1 tw-cursor-pointer tw-text-accent-1 tw-underline hover:tw-opacity-90', children: "Learn More" })) : null] })] }));
}
exports.default = CommunitySettingDescription;
