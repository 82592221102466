"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
/* IMPORT CORE MODULES */
var lucide_react_1 = require("lucide-react");
var General_1 = require("../icons/General");
// ✔ create channel's icon component
/**
 * #### Channel's Icon Component ####
 * @description This component can render channel's icon base on type and private value.
 * @param { ChannelIconProps } props - What is channel's icon component properties?
 * @returns { React.ReactNode } Channel's icon component
 *
 * @example
 * ```typescriptreact
 * import { ChannelIcon } from '@/components/molecules'
 * <ChannelIcon type='open' isPrivate />
 * ```
 *
 * @example
 * ```typescriptreact
 * import { Channel } from '@/components/molecules'
 * <Channel.Icon type='open' isPrivate />
 * ```
 *
 * @version 1.0.0
 * @since 10.3.0
 * @author Wunna
 */
function ChannelIcon(_a) {
    var type = _a.type, isPrivate = _a.isPrivate;
    switch (type) {
        case 'open':
            return (0, jsx_runtime_1.jsx)(General_1.HashIcon, { isPrivate: isPrivate });
        case 'whisper':
            return (0, jsx_runtime_1.jsx)(General_1.WhisperIcon, { isPrivate: isPrivate });
        case 'announce':
            return (0, jsx_runtime_1.jsx)(General_1.AnnouncementIcon, { isPrivate: isPrivate });
        case 'audio':
            return (0, jsx_runtime_1.jsx)(General_1.AudioIcon, { isPrivate: isPrivate });
        case 'video':
            return (0, jsx_runtime_1.jsx)(lucide_react_1.VideoIcon, {});
        case 'direct':
            return (0, jsx_runtime_1.jsx)(General_1.DMICon, { isPrivate: isPrivate });
        default:
            return null;
    }
}
exports.default = ChannelIcon;
