"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCommunitySettingsHook = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var CommunitySettingsContext = (0, react_1.createContext)({
    backClickHandler: function () {
        throw new Error('Function not implemented.');
    },
    learnMoreClickHandler: function (id) {
        throw new Error('Function not implemented.');
    },
    manageServerHandler: function (action, path, $event) {
        throw new Error('Function not implemented.');
    },
});
var useCommunitySettingsHook = function (_a) {
    var learnMoreId = _a.learnMoreId;
    var context = (0, react_1.useContext)(CommunitySettingsContext);
    if (!context)
        throw new Error('"useCommunitySettingsHook" must be used within a "CommunitySettingsProvider"');
    return {
        backClickHandler: context.backClickHandler,
        learnMoreClickHandler: function () { return context.learnMoreClickHandler(learnMoreId); },
        downloadUserListClickHandler: function () { return context.manageServerHandler('exportUserList'); },
    };
};
exports.useCommunitySettingsHook = useCommunitySettingsHook;
var CommunitySettingsProvider = function (_a) {
    var sidebarOpener = _a.sidebarOpener, showIntercomArticle = _a.showIntercomArticle, manageServer = _a.manageServer, children = _a.children;
    var backClickHandler = (0, react_1.useCallback)(function () {
        if (!sidebarOpener)
            throw Error("'sidebarOpener' function need to perform this feature.");
        sidebarOpener(null, 'close', 'serverSettings');
    }, []);
    var learnMoreClickHandler = (0, react_1.useCallback)(function (id) {
        if (!showIntercomArticle)
            throw Error("'showIntercomArticle' function need to perform this feature.");
        showIntercomArticle(id);
    }, []);
    var manageServerHandler = (0, react_1.useCallback)(function (action, path, event) {
        if (!manageServer)
            throw Error("'manageServer' function need to perform this feature.");
        manageServer(action, path, event);
    }, []);
    return ((0, jsx_runtime_1.jsx)(CommunitySettingsContext.Provider, { value: {
            backClickHandler: backClickHandler,
            learnMoreClickHandler: learnMoreClickHandler,
            manageServerHandler: manageServerHandler,
        }, children: children }));
};
exports.default = CommunitySettingsProvider;
