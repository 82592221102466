"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SheetTrigger = exports.SheetTitle = exports.SheetHeader = exports.SheetFooter = exports.SheetDescription = exports.SheetContent = exports.SheetClose = exports.Sheet = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var utils_1 = require("@/lib/utils");
var SheetPrimitive = __importStar(require("@radix-ui/react-dialog"));
var class_variance_authority_1 = require("class-variance-authority");
var React = __importStar(require("react"));
var Sheet = SheetPrimitive.Root;
exports.Sheet = Sheet;
var SheetTrigger = SheetPrimitive.Trigger;
exports.SheetTrigger = SheetTrigger;
var SheetClose = SheetPrimitive.Close;
exports.SheetClose = SheetClose;
var SheetPortal = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(SheetPrimitive.Portal, __assign({ className: (0, utils_1.cn)(className) }, props)));
};
SheetPortal.displayName = SheetPrimitive.Portal.displayName;
var SheetOverlay = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(SheetPrimitive.Overlay, __assign({ className: (0, utils_1.cn)('tw-fixed tw-inset-0 tw-z-50 tw-bg-black/80 tw-backdrop-blur-sm data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0', className) }, props, { ref: ref })));
});
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;
var sheetVariants = (0, class_variance_authority_1.cva)('tw-fixed tw-z-50 tw-gap-4 tw-p-6 tw-shadow-lg tw-transition tw-ease-in-out data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-duration-300 data-[state=open]:tw-duration-500', {
    variants: {
        side: {
            top: 'tw-inset-x-0 tw-top-0 data-[state=closed]:tw-slide-out-to-top data-[state=open]:tw-slide-in-from-top',
            bottom: 'tw-inset-x-0 data-[state=closed]:tw-slide-out-to-bottom data-[state=open]:tw-slide-in-from-bottom',
            left: 'tw-inset-y-0 tw-left-0 tw-h-full data-[state=closed]:tw-slide-out-to-left data-[state=open]:tw-slide-in-from-left tw-w-[350px]',
            right: 'tw-inset-y-0 tw-right-0 tw-h-full data-[state=closed]:tw-slide-out-to-right data-[state=open]:tw-slide-in-from-right tw-w-[350px]',
        },
    },
    defaultVariants: {
        side: 'left',
    },
});
var SheetContent = React.forwardRef(function (_a, ref) {
    var _b = _a.side, side = _b === void 0 ? 'left' : _b, className = _a.className, children = _a.children, props = __rest(_a, ["side", "className", "children"]);
    return ((0, jsx_runtime_1.jsxs)(SheetPortal, { children: [(0, jsx_runtime_1.jsx)(SheetOverlay, {}), (0, jsx_runtime_1.jsx)(SheetPrimitive.Content, __assign({ ref: ref, className: (0, utils_1.cn)(sheetVariants({ side: side }), className, 'tw-bg-background-4') }, props, { children: children }))] }));
});
exports.SheetContent = SheetContent;
SheetContent.displayName = SheetPrimitive.Content.displayName;
var SheetHeader = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: (0, utils_1.cn)('tw-flex tw-flex-col tw-space-y-2 sm:tw-text-left', className) }, props)));
};
exports.SheetHeader = SheetHeader;
SheetHeader.displayName = 'SheetHeader';
var SheetFooter = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: (0, utils_1.cn)('tw-flex tw-flex-col-reverse sm:tw-flex-row sm:tw-justify-end sm:tw-space-x-2', className) }, props)));
};
exports.SheetFooter = SheetFooter;
SheetFooter.displayName = 'SheetFooter';
var SheetTitle = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(SheetPrimitive.Title, __assign({ ref: ref, className: (0, utils_1.cn)('tw-text-lg tw-font-semibold tw-text-text-1', className) }, props)));
});
exports.SheetTitle = SheetTitle;
SheetTitle.displayName = SheetPrimitive.Title.displayName;
var SheetDescription = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(SheetPrimitive.Description, __assign({ ref: ref, className: (0, utils_1.cn)('tw-text-sm tw-text-text-1', className) }, props)));
});
exports.SheetDescription = SheetDescription;
SheetDescription.displayName = SheetPrimitive.Description.displayName;
