"use strict";
'use client';
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var AccordionItem_1 = require("./AccordionItem");
var AccordionBody = function (_a) {
    var children = _a.children, _b = _a.as, as = _b === void 0 ? 'div' : _b, _c = _a.className, className = _c === void 0 ? '' : _c;
    var _d = (0, react_1.useContext)(AccordionItem_1.AccordionItemContext), hash = _d.hash, transition = _d.transition;
    var TagName = (0, react_1.useMemo)(function () {
        if (as) {
            return as;
        }
        return 'div';
    }, [as]);
    var transitionData = (0, react_1.useMemo)(function () {
        var defaultData = {
            duration: '300ms',
            timingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
        };
        if (transition && 'duration' in transition && transition.duration) {
            defaultData.duration = transition.duration;
        }
        if (transition && 'timingFunction' in transition && transition.timingFunction) {
            defaultData.timingFunction = transition.timingFunction;
        }
        return defaultData;
    }, [transition]);
    return ((0, jsx_runtime_1.jsx)(TagName, { id: "content-".concat(hash), "aria-labelledby": "button-".concat(hash), className: className, style: {
            maxHeight: '0px',
            transitionProperty: 'max-height',
            overflow: 'hidden',
            transitionDuration: transitionData.duration,
            transitionTimingFunction: transitionData.timingFunction,
        }, children: children }));
};
exports.default = AccordionBody;
