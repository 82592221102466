"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var chart_js_1 = require("chart.js");
var react_chartjs_2_1 = require("react-chartjs-2");
chart_js_1.Chart.register(chart_js_1.ArcElement, chart_js_1.Tooltip, chart_js_1.Legend);
/**
 * DoughnutChart Component
 */
function DoughnutChart() {
    var _a, _b;
    var summaryRef = (0, react_1.useRef)(null);
    var legendItems = (_b = (_a = summaryRef === null || summaryRef === void 0 ? void 0 : summaryRef.current) === null || _a === void 0 ? void 0 : _a.legend) === null || _b === void 0 ? void 0 : _b.legendItems;
    var data = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    var options = {
        plugins: {
            legend: {
                display: false,
                labels: {
                    font: {
                        size: 12,
                    },
                },
            },
        },
    };
    return ((0, jsx_runtime_1.jsxs)("section", { className: 'tw-flex tw-items-center tw-gap-[50px]', children: [(0, jsx_runtime_1.jsx)("div", { className: 'tw-h-[161px] tw-w-[161px]', children: (0, jsx_runtime_1.jsx)(react_chartjs_2_1.Doughnut, { data: data, ref: summaryRef, options: options }) }), (0, jsx_runtime_1.jsx)("div", { className: 'tw-flex tw-flex-col tw-items-start tw-space-y-1', children: legendItems === null || legendItems === void 0 ? void 0 : legendItems.map(function (item, idx) {
                    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-1', children: [(0, jsx_runtime_1.jsx)("div", { className: "tw-h-[8px] tw-w-[8px] tw-rounded-full", style: {
                                    backgroundColor: item.fillStyle,
                                } }), (0, jsx_runtime_1.jsx)("span", { className: 'tw-text-xs tw-font-normal tw-text-text-4', children: item.text })] }, idx));
                }) })] }));
}
exports.default = DoughnutChart;
