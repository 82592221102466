"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var tailwind_merge_1 = require("tailwind-merge");
// ☐ create activity control item component
function ActivityControlItem(_a) {
    var name = _a.name, isActive = _a.isActive, onClick = _a.onClick;
    return ((0, jsx_runtime_1.jsx)("button", { className: (0, tailwind_merge_1.twMerge)('tw-whitespace-nowrap tw-border-b-2 tw-border-solid tw-px-4 tw-pb-2 tw-font-medium tw-capitalize', isActive ? 'tw-border-accent-2 tw-text-text-1' : 'tw-border-none tw-text-text-3'), onClick: onClick, children: name }));
}
// ☐ create activity control component
function ActivityControl(_a) {
    var activeCategory = _a.activeCategory, setActiveCategory = _a.setActiveCategory, isThreadsFeatureOn = _a.isThreadsFeatureOn;
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'no-scrollbar tw-flex tw-flex-nowrap tw-overflow-x-auto tw-overflow-y-hidden tw-border-b-2 tw-border-solid tw-border-divider-lines-1 tw-px-2', children: [(0, jsx_runtime_1.jsx)(ActivityControlItem, { name: 'all', isActive: activeCategory === 'all', onClick: function () { return setActiveCategory('all'); } }), (0, jsx_runtime_1.jsx)(ActivityControlItem, { name: 'replies', isActive: activeCategory === 'replies', onClick: function () { return activeCategory !== 'replies' && setActiveCategory('replies'); } }), (0, jsx_runtime_1.jsx)(ActivityControlItem, { name: 'mentions', isActive: activeCategory === 'mentions', onClick: function () { return activeCategory !== 'mentions' && setActiveCategory('mentions'); } }), isThreadsFeatureOn && ((0, jsx_runtime_1.jsx)(ActivityControlItem, { name: 'threads', isActive: activeCategory === 'threads', onClick: function () { return activeCategory !== 'threads' && setActiveCategory('threads'); } })), (0, jsx_runtime_1.jsx)(ActivityControlItem, { name: 'reactions', isActive: activeCategory === 'reactions', onClick: function () { return activeCategory !== 'reactions' && setActiveCategory('reactions'); } }), (0, jsx_runtime_1.jsx)(ActivityControlItem, { name: 'trade ideas', isActive: activeCategory === 'trade-ideas', onClick: function () { return activeCategory !== 'trade-ideas' && setActiveCategory('trade-ideas'); } })] }));
}
exports.default = ActivityControl;
