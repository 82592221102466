"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddNewGraphState = exports.AddDashboardDisableState = exports.SelectedDashboardState = exports.DashboardsState = void 0;
var demo_1 = require("@/lib/constants/demo");
var recoil_1 = require("recoil");
exports.DashboardsState = (0, recoil_1.atom)({
    key: 'DashboardsState',
    default: demo_1.DASHBOARD_DEMOS,
});
exports.SelectedDashboardState = (0, recoil_1.atom)({
    key: 'SelectedDashboardState',
    default: {
        name: 'Overview',
        show: true,
    },
});
/** Add Circle Disable When Adding new Dashboard */
exports.AddDashboardDisableState = (0, recoil_1.atom)({
    key: 'AddDashboardDisable',
    default: false,
});
/** Add New Graph Show/Hide Form State */
exports.AddNewGraphState = (0, recoil_1.atom)({
    key: 'AddNewGraphState',
    default: false,
});
