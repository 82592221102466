"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Transition_Animation_Variants = exports.NoRewardsAvailable = exports.BacktoStoreButton = exports.ViewAllRewardsButton = exports.PreviewOverlayActions = exports.StoreBanner = exports.StoreHeading = exports.StoreToggle = exports.StoreWrapper = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var General_1 = require("@/components/molecules/icons/General");
var button_1 = require("@/components/ui/button");
var utils_1 = require("@/lib/utils");
var DialogPrimitive = __importStar(require("@radix-ui/react-dialog"));
var framer_motion_1 = require("framer-motion");
var react_1 = require("react");
/** @private Toggle Tabs */
var tabs = ['Store', 'Purchased'];
/** Store Container Wrapper */
function StoreWrapper(_a) {
    var children = _a.children, className = _a.className;
    return ((0, jsx_runtime_1.jsx)("div", { className: (0, utils_1.cn)('gap-[24px] tw-mx-auto tw-flex tw-w-full tw-max-w-[1760px] tw-flex-col tw-space-y-[24px] tw-px-[20px] md:tw-px-[40px]', className), children: children }));
}
exports.StoreWrapper = StoreWrapper;
/** Store Toggle At the Top */
function StoreToggle(_a) {
    var activeTab = _a.activeTab, onChange = _a.onChange, disabled = _a.disabled;
    return ((0, jsx_runtime_1.jsx)("div", { className: 'tw-flex tw-items-center tw-justify-center', children: tabs.map(function (tab) {
            return ((0, jsx_runtime_1.jsxs)("button", { onClick: function () { return (!disabled ? onChange(tab) : {}); }, className: (0, utils_1.cn)('tw-relative tw-h-[36px] tw-rounded-[8px]', activeTab === tab && 'tw-text-accent-1', tab === 'Store' ? 'tw-w-[58px]' : 'tw-w-[91px]'), children: [(0, jsx_runtime_1.jsx)("span", { className: 'tw-relative tw-z-50', children: tab }), activeTab === tab && (0, jsx_runtime_1.jsx)(ToggleActiveBackground, {})] }, tab));
        }) }));
}
exports.StoreToggle = StoreToggle;
/** Store Heading and Description */
function StoreHeading(_a) {
    var name = _a.name, description = _a.description;
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-mt-4 tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-2', children: [(0, jsx_runtime_1.jsx)("h1", { className: 'tw-text-center tw-text-[22px] tw-font-bold tw-text-text-1', children: name }), (0, jsx_runtime_1.jsx)("div", { className: 'ProseMirror no-border tw-flex tw-items-center tw-justify-center tw-self-center !tw-bg-transparent tw-text-[16px] tw-text-text-1', dangerouslySetInnerHTML: {
                    __html: description,
                } })] }));
}
exports.StoreHeading = StoreHeading;
/** Store Banner */
function StoreBanner(_a) {
    var src = _a.src;
    return ((0, jsx_runtime_1.jsx)("section", { className: 'tw-w-full', children: (0, jsx_runtime_1.jsx)("img", { src: src, width: '100%', className: 'tw-aspect-[4/1] !tw-h-[331px] tw-rounded-[20px] tw-object-cover' }) }));
}
exports.StoreBanner = StoreBanner;
/** Store Preview Overlay Actions */
function PreviewOverlayActions(_a) {
    var onClose = _a.onClose, handleSaveChanges = _a.handleSaveChanges;
    return ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(DialogPrimitive.Portal, { children: (0, jsx_runtime_1.jsx)(DialogPrimitive.Overlay, { className: 'tw-fixed tw-right-[135px] tw-top-6 tw-z-[999]', onClick: function (e) {
                        console.log('on close');
                        e.stopPropagation();
                        e.preventDefault();
                        onClose();
                    }, children: (0, jsx_runtime_1.jsx)(button_1.Button, { size: 'sm', variant: 'link', children: "Back" }) }) }), (0, jsx_runtime_1.jsx)(DialogPrimitive.Portal, { children: (0, jsx_runtime_1.jsx)(DialogPrimitive.Overlay, { className: 'tw-fixed tw-right-[16px] tw-top-6 tw-z-[999]', onClick: function (e) {
                        console.log('on save');
                        e.stopPropagation();
                        e.preventDefault();
                    }, children: (0, jsx_runtime_1.jsx)(button_1.Button, { size: 'sm', onClick: function () {
                            handleSaveChanges();
                            onClose();
                        }, className: 'tw-text-text-1', variant: 'success', children: "Save Changes" }) }) })] }));
}
exports.PreviewOverlayActions = PreviewOverlayActions;
/** View All Reards Button */
function ViewAllRewardsButton(_a) {
    var onClick = _a.onClick;
    return ((0, jsx_runtime_1.jsxs)("button", { onClick: onClick, className: 'tw-flex tw-items-center tw-gap-1 tw-font-bold tw-text-accent-2 hover:tw-text-accent-1', children: [(0, jsx_runtime_1.jsx)("span", { children: "View all rewards" }), " ", (0, jsx_runtime_1.jsx)(General_1.RightArrowVectorSVG, {})] }));
}
exports.ViewAllRewardsButton = ViewAllRewardsButton;
/** Back to Store Button */
function BacktoStoreButton(_a) {
    var onClick = _a.onClick;
    return ((0, jsx_runtime_1.jsxs)("button", { onClick: onClick, className: 'tw-flex tw-items-center tw-gap-1 tw-font-bold tw-text-accent-2 hover:tw-text-accent-1', children: [(0, jsx_runtime_1.jsx)(General_1.RightArrowVectorSVG, { className: 'tw-rotate-180' }), " ", (0, jsx_runtime_1.jsx)("span", { children: "Back to store" })] }));
}
exports.BacktoStoreButton = BacktoStoreButton;
/** No Reards Avaialbe State in Store Front */
function NoRewardsAvailable() {
    return ((0, jsx_runtime_1.jsx)("div", { className: 'tw-flex tw-h-[736px] tw-w-full tw-items-center tw-justify-center', children: (0, jsx_runtime_1.jsx)("h3", { className: 'tw-text-center tw-text-[16px] tw-text-text-4', children: "There are no rewards available. Please check back again later!" }) }));
}
exports.NoRewardsAvailable = NoRewardsAvailable;
/** Store Front & Rewards Details Screens Transitions Animation */
exports.Transition_Animation_Variants = {
    initial: function (direction) { return ({
        x: direction > 0 ? 300 : -300,
        opacity: 0,
    }); },
    animate: { x: 0, opacity: 1 },
    exit: function (direction) { return ({
        x: direction > 0 ? -300 : 300,
        opacity: 0,
    }); },
};
/** @private Animated Active Background */
function ToggleActiveBackground() {
    return ((0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { className: 'tw-absolute tw-bottom-0 tw-left-0 tw-z-0 tw-h-full tw-rounded-[8px] tw-bg-background-4', layoutId: 'navbar', "aria-hidden": 'true', style: {
            width: '100%',
        }, transition: {
            type: 'tween',
            bounce: 0.25,
            duration: 0.3,
            stiffness: 300,
            damping: 29,
        } }));
}
