"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var dropdown_menu_1 = require("@/components/ui/dropdown-menu");
/**
 * Dropdown menu with `check icon` to show the current selected item
 */
function CheckDropdown(_a) {
    var selected = _a.selected, onChange = _a.onChange, children = _a.children, disabled = _a.disabled, items = _a.items, side = _a.side, align = _a.align, contentClassNames = _a.contentClassNames;
    return ((0, jsx_runtime_1.jsxs)(dropdown_menu_1.DropdownMenu, { children: [(0, jsx_runtime_1.jsx)(dropdown_menu_1.DropdownMenuTrigger, { disabled: disabled, children: children }), (0, jsx_runtime_1.jsx)(dropdown_menu_1.DropdownMenuContent, { side: side, align: align, className: contentClassNames, children: items.map(function (item, idx) { return ((0, jsx_runtime_1.jsx)(dropdown_menu_1.DropdownMenuCheckboxItem, { textValue: item.text, onClick: function () { return onChange(item.value); }, checked: item.value === selected, className: 'tw-flex tw-cursor-pointer tw-items-center tw-justify-between', children: (0, jsx_runtime_1.jsx)("div", { className: 'tw-mr-5 tw-flex tw-items-center tw-gap-3', children: (0, jsx_runtime_1.jsx)("span", { className: 'tw-text-sm', children: item.text }) }) }, idx)); }) })] }));
}
exports.default = CheckDropdown;
