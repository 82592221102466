"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreFrontSkeleton = exports.MyProfileSkeleton = exports.ChatPreloaderSkeleton = exports.ParagonIntegrationSkeleton = exports.MentionChannelListSkeleton = exports.MentionUserListSkeleton = exports.DashboardSkeleton = exports.Skeleton = exports.shimmer = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var utils_1 = require("@/lib/utils");
exports.shimmer = 'tw-relative tw-overflow-hidden before:tw-absolute before:tw-inset-0 before:-tw-translate-x-full before:tw-animate-[shimmer_1.5s_infinite] before:tw-bg-gradient-to-r before:tw-from-transparent before:tw-via-background-6 before:tw-to-transparent';
/**
 * Based Skeleton component
 */
function Skeleton(_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ role: 'progressbar', "aria-label": 'skeleton', className: (0, utils_1.cn)('tw-animate-pulse tw-rounded-md tw-bg-background-7 tw-opacity-30', className, exports.shimmer) }, props)));
}
exports.Skeleton = Skeleton;
/** Sidebar and Dashboard Skeleton */
function DashboardSkeleton() {
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-relative tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-10 md:tw-flex-row', children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-w-full tw-space-y-2 md:tw-w-[320px]', children: [(0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[24px] tw-w-full md:tw-w-[320px]' }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[24px] tw-w-full md:tw-w-[320px]' }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[24px] tw-w-full md:tw-w-[320px]' }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[24px] tw-w-full md:tw-w-[320px]' }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[24px] tw-w-full md:tw-w-[320px]' })] }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[100vh] tw-w-full' })] }));
}
exports.DashboardSkeleton = DashboardSkeleton;
/** Mention UserList Skeleton List */
function MentionUserListSkeleton(_a) {
    var isMobileOrTablet = _a.isMobileOrTablet;
    var count = isMobileOrTablet ? [1, 2, 3, 4] : [1, 2, 3, 4, 5, 6, 7];
    return ((0, jsx_runtime_1.jsx)("div", { className: 'tw-space-y-3', children: count.map(function (item) { return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-mx-2 tw-flex tw-items-center tw-justify-between tw-rounded-sm tw-p-2', children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-[10px]', children: [(0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[35px] tw-w-[35px]', style: { borderRadius: '100%' } }), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-space-y-2', children: [(0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[10px] tw-w-[100px]' }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[10px] tw-w-[100px]' })] })] }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-truncate125 tw-h-[10px] tw-w-[30px] tw-text-xs tw-text-text-5' })] }, item)); }) }));
}
exports.MentionUserListSkeleton = MentionUserListSkeleton;
/** Mention Channel List Skeleton */
function MentionChannelListSkeleton() {
    return ((0, jsx_runtime_1.jsx)("div", { className: 'tw-space-y-3', children: [1, 2, 3, 4, 5, 6, 7].map(function (item) { return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-mx-2 tw-flex tw-items-center tw-justify-between tw-rounded-sm tw-p-2', children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-[10px]', children: [(0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[17px] tw-w-[17px]' }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[10px] tw-w-[100px]' })] }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[10px] tw-w-[30px]' })] }, item)); }) }));
}
exports.MentionChannelListSkeleton = MentionChannelListSkeleton;
/** Paragon Integration Skeleton */
function ParagonIntegrationSkeleton() {
    return ((0, jsx_runtime_1.jsx)("div", { className: 'tw-mr-auto tw-flex tw-w-full tw-flex-col tw-gap-6 tw-rounded-md tw-bg-background-4 tw-py-[30px] tw-pl-[20px] tw-pr-[25px] md:tw-w-[400px]', children: [1, 2].map(function (item) { return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-w-full tw-items-center tw-justify-between', children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-4', children: [(0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[50px] tw-w-[50px] !tw-rounded-[100%]' }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[20px] tw-w-[80px] tw-rounded-md' })] }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[40px] tw-w-[90px] tw-rounded-md tw-border-2 tw-border-background-4' })] }, item)); }) }));
}
exports.ParagonIntegrationSkeleton = ParagonIntegrationSkeleton;
/** Forum Message Chats Infinite Scroll PreLoader */
function ChatPreloaderSkeleton(_a) {
    var prefetch = _a.prefetch;
    var count = prefetch ? [1, 2] : [1];
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-relative tw-space-y-2 tw-px-6 tw-opacity-100', children: [count.map(function (item) { return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-flex-col tw-gap-2 tw-py-4', children: [(0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[35px] tw-w-[35px] !tw-bg-background-6', style: { borderRadius: '100%' } }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[15px] tw-w-[80%] !tw-bg-background-6' }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[15px] tw-w-[60%] !tw-bg-background-6' }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[15px] tw-w-[70%] !tw-bg-background-6' }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[15px] tw-w-[50%] !tw-bg-background-6' })] }, item)); }), !prefetch ? ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-flex-col tw-gap-2', id: 'n', children: [(0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[35px] tw-w-[35px]', style: { borderRadius: '100%' } }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[15px] tw-w-[10%]' }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[200px] tw-w-[25%]' })] })) : null] }));
}
exports.ChatPreloaderSkeleton = ChatPreloaderSkeleton;
/** My Profile Skeleton */
function MyProfileSkeleton() {
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-space-y-2', children: [(0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[30px] tw-w-full' }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[50px] tw-w-full' }), (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[90vh] tw-w-full' })] }));
}
exports.MyProfileSkeleton = MyProfileSkeleton;
/** Store Front Sekeleon */
function StoreFrontSkeleton() {
    return ((0, jsx_runtime_1.jsx)("div", { className: 'tw-h-[100vh] tw-w-full', children: (0, jsx_runtime_1.jsx)(Skeleton, { className: 'tw-h-[100vh] tw-w-full' }) }));
}
exports.StoreFrontSkeleton = StoreFrontSkeleton;
