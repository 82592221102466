"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.profanityWords = void 0;
/**
 * Profanity words used in Content Moderation
 */
exports.profanityWords = [
    'anal',
    'anus',
    'arse',
    'asshole',
    'ballsack',
    'bastard',
    'bitch',
    'biatch',
    'bloody',
    'blowjob',
    'blow job',
    'bollock',
    'bollok ',
    'boner',
    'clitoris',
    'cock',
    'coon',
    'crap',
    'cunt',
    'damn',
    'dick',
    'dildo',
    'dyke',
    'fag',
    'faggot',
    'feck',
    'fellate',
    'fellatio',
    'felching',
    'fuck',
    'f u c k',
    'goddamn',
    'god damn',
    'hell',
    'hoe',
    'jerk',
    'jizz',
    'knobend',
    'labia',
    'motherfuck',
    'motherfucker',
    'nig',
    'nigger',
    'nigga',
    'penis',
    'piss',
    'pube',
    'pussy',
    'sex',
    'shit',
    'slut',
    'son of a bitch',
    'tit',
    'turd',
    'twat',
    'vagina',
    'wank',
    'whore',
];
