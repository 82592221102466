"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
// ✔ create message's reaction component
/**
 * ### Message's Reaction Component ###
 * @description This component can render single message's reaction.
 * @param { MessageReactionProps } props - What is message's reaction component property?
 * @returns { React.ReactNode } Message's reaction component
 *
 * @example
 * ```typescriptreact
 * import { MessageReaction } from '@/components/molecules'
 * <MessageReaction path={REACTION_IMAGE_PATH} count={REACTION_COUNT} />
 * ```
 *
 * @example
 * ```typescriptreact
 * import { Message } from '@/components/molecules'
 * <Message.Reaction path={REACTION_IMAGE_PATH} count={REACTION_COUNT} />
 * ```
 *
 * @version 1.0.0
 * @since 10.3.0
 * @author Wunna
 */
function MessageReaction(props) {
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-gap-1.5 tw-rounded-md tw-bg-background-6 tw-px-2 tw-py-0.5 tw-text-xs', children: [(0, jsx_runtime_1.jsx)("img", { className: 'tw-h-3 tw-w-3', src: props.path }), (0, jsx_runtime_1.jsx)("span", { children: props.count })] }));
}
exports.default = MessageReaction;
