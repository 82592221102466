"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MOCK_REWARDS_MANAGEMENT = exports.columns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var utils_1 = require("@/lib/utils");
/**
 * Rewards Management Table Columns
 */
exports.columns = [
    {
        accessorKey: 'redemptionId',
        enableSorting: true,
        header: 'Redemption ID',
        cell: function (_a) {
            var row = _a.row;
            return row.getValue('redemptionId') || '-';
        },
    },
    {
        accessorKey: 'user',
        enableSorting: true,
        header: 'Name of User',
        cell: function (_a) {
            var row = _a.row;
            var user = row.getValue('user');
            return ((0, jsx_runtime_1.jsx)("div", { className: 'tw-flex tw-justify-between', children: (0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-2', children: [user && ((0, jsx_runtime_1.jsx)("img", { src: user.user_image, width: 50, height: 50, className: 'tw-h-10 tw-w-10 tw-rounded-full tw-object-cover', alt: user.displayname || user.nicename })), user && ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-flex-col', children: [(0, jsx_runtime_1.jsx)("div", { className: 'tw-flex tw-gap-3', children: (0, jsx_runtime_1.jsx)("h4", { className: 'tw-text-[12px] tw-font-normal tw-text-text-1 tw-truncate tw-w-[150px]', children: user.displayname || user.nicename }) }), (0, jsx_runtime_1.jsxs)("h5", { className: 'tw-mt-[2px] tw-text-[12px] tw-text-text-1 tw-truncate tw-w-[150px]', children: ["@", user.nicename] })] })), !user && (0, jsx_runtime_1.jsx)("span", { children: "-" })] }) }));
        },
    },
    {
        accessorKey: 'name',
        enableSorting: true,
        header: 'Name of Reward',
        cell: function (_a) {
            var row = _a.row;
            return row.getValue('name');
        },
    },
    {
        accessorKey: 'type',
        enableSorting: true,
        header: 'Type of reward',
        cell: function (_a) {
            var row = _a.row;
            return row.getValue('type');
        },
    },
    {
        accessorKey: 'quantity',
        enableSorting: true,
        header: 'Quantity',
        cell: function (_a) {
            var row = _a.row;
            return row.getValue('quantity') || '-';
        },
    },
    {
        accessorKey: 'price',
        enableSorting: true,
        header: 'Price',
        cell: function (_a) {
            var row = _a.row;
            var price = row.getValue('price');
            return (0, jsx_runtime_1.jsx)("span", { children: "".concat(price, " Coins") });
        },
    },
    {
        accessorKey: 'purchasedDate',
        enableSorting: true,
        header: 'Date of Purchase',
        cell: function (_a) {
            var row = _a.row;
            var purchasedDate = row.getValue('purchasedDate');
            if (purchasedDate) {
                return (0, jsx_runtime_1.jsx)("span", { children: (0, utils_1.formatDate)(purchasedDate) });
            }
            else {
                return (0, jsx_runtime_1.jsx)("span", { children: "-" });
            }
        },
    },
    {
        accessorKey: 'voucherCode',
        enableSorting: true,
        header: 'Voucher Code',
        cell: function (_a) {
            var row = _a.row;
            return row.getValue('voucherCode');
        },
    },
    {
        accessorKey: 'expiryDate',
        enableSorting: true,
        header: 'Voucher Expiry Date',
        // TODO: expiry date logic
        cell: function (_a) {
            var row = _a.row;
            return (0, utils_1.formatDate)(row.getValue('expiryDate'));
        },
    },
    {
        accessorKey: 'status',
        enableSorting: true,
        header: 'Status',
        cell: function (_a) {
            var row = _a.row;
            var status = row.getValue('status');
            if (status === 'redeemed') {
                return (0, jsx_runtime_1.jsx)(StatusBadge, { text: 'Completed', status: 'redeemed' });
            }
            else {
                return (0, jsx_runtime_1.jsx)(StatusBadge, { text: 'Voucher Expired', status: 'expired' });
            }
        },
    },
];
function StatusBadge(_a) {
    var text = _a.text, status = _a.status;
    return ((0, jsx_runtime_1.jsx)("div", { className: (0, utils_1.cn)('tw-text-text-1 tw-whitespace-nowrap tw-w-fit tw-text-[12px] tw-px-[8px] tw-py-[4px] tw-rounded-[4px]', status === 'expired' ? 'tw-bg-error-2' : 'tw-bg-success-1'), children: text }));
}
/**
 * Mock data for Rewards Management Table
 */
exports.MOCK_REWARDS_MANAGEMENT = [
    {
        redemptionId: '02809251',
        user: {
            user_image: 'https://lh3.googleusercontent.com/a/ACg8ocILL-CzUE_bnmw1KIkRG_471jgnNjFB-yYegaQFiXSrhg=s96-c',
            displayname: 'thuta_sann',
            nicename: 'thuta_sann',
        },
        name: 'Trading Ideas Commentary',
        type: 'voucher',
        quantity: 1,
        price: 300,
        purchasedDate: '2024-07-23T05:23:01.611Z',
        voucherCode: '12145655',
        expiryDate: '2024-07-23T05:23:01.611Z',
        status: 'redeemed',
    },
    {
        redemptionId: '02809252',
        user: {
            user_image: 'https://lh3.googleusercontent.com/a/ACg8ocILL-CzUE_bnmw1KIkRG_471jgnNjFB-yYegaQFiXSrhg=s96-c',
            displayname: 'john_doe',
            nicename: 'john_doe',
        },
        name: 'iPhone 14',
        type: 'voucher',
        quantity: 1,
        price: 2000,
        purchasedDate: '2024-07-23T05:23:01.611Z',
        voucherCode: '12145656',
        expiryDate: '2024-07-23T05:23:01.611Z',
        status: 'redeemed',
    },
    {
        redemptionId: '02809253',
        user: {
            user_image: 'https://lh3.googleusercontent.com/a/ACg8ocILL-CzUE_bnmw1KIkRG_471jgnNjFB-yYegaQFiXSrhg=s96-c',
            displayname: 'jane_smith',
            nicename: 'jane_smith',
        },
        name: 'iPhone 14',
        type: 'voucher',
        quantity: 1,
        price: 3000,
        purchasedDate: '',
        voucherCode: '12145657',
        expiryDate: '2024-07-23T05:23:01.611Z',
        status: 'redeemed',
    },
    {
        redemptionId: '02809254',
        user: {
            user_image: 'https://lh3.googleusercontent.com/a/ACg8ocILL-CzUE_bnmw1KIkRG_471jgnNjFB-yYegaQFiXSrhg=s96-c',
            displayname: 'michael_brown',
            nicename: 'michael_brown',
        },
        name: 'iPhone 14 $20 Voucher',
        type: 'voucher',
        quantity: 1,
        price: 1200,
        purchasedDate: '2024-07-23T05:23:01.611Z',
        voucherCode: '12145658',
        expiryDate: '2024-07-23T05:23:01.611Z',
        status: 'redeemed',
    },
    {
        redemptionId: '02809255',
        user: {
            user_image: 'https://lh3.googleusercontent.com/a/ACg8ocILL-CzUE_bnmw1KIkRG_471jgnNjFB-yYegaQFiXSrhg=s96-c',
            displayname: 'emily_jones',
            nicename: 'emily_jones',
        },
        name: 'Forex Tarding Reward',
        type: 'voucher',
        quantity: 1,
        price: 2300,
        purchasedDate: '2024-07-23T05:23:01.611Z',
        voucherCode: '12145659',
        expiryDate: '2024-07-23T05:23:01.611Z',
        status: 'expired',
    },
    {
        redemptionId: '02809256',
        user: {
            user_image: 'https://lh3.googleusercontent.com/a/ACg8ocILL-CzUE_bnmw1KIkRG_471jgnNjFB-yYegaQFiXSrhg=s96-c',
            displayname: 'david_wilson',
            nicename: 'david_wilson',
        },
        name: 'Frontend Coding Reward',
        type: 'voucher',
        quantity: 1,
        price: 4300,
        purchasedDate: '2024-07-23T05:23:01.611Z',
        voucherCode: '12145660',
        expiryDate: '2024-07-23T05:23:01.611Z',
        status: 'redeemed',
    },
    {
        redemptionId: '02809257',
        user: {
            user_image: 'https://lh3.googleusercontent.com/a/ACg8ocILL-CzUE_bnmw1KIkRG_471jgnNjFB-yYegaQFiXSrhg=s96-c',
            displayname: 'sarah_taylor',
            nicename: 'sarah_taylor',
        },
        name: 'Backend Coding Reward',
        type: 'voucher',
        quantity: 1,
        price: 5300,
        purchasedDate: '2024-07-23T05:23:01.611Z',
        voucherCode: '12145661',
        expiryDate: '2024-07-23T05:23:01.611Z',
        status: 'redeemed',
    },
    {
        redemptionId: '02809258',
        user: {
            user_image: 'https://lh3.googleusercontent.com/a/ACg8ocILL-CzUE_bnmw1KIkRG_471jgnNjFB-yYegaQFiXSrhg=s96-c',
            displayname: 'daniel_martinez',
            nicename: 'daniel_martinez',
        },
        name: 'Full Stack Coding',
        type: 'voucher',
        quantity: 1,
        price: 8300,
        purchasedDate: '2024-07-23T05:23:01.611Z',
        voucherCode: '12145662',
        expiryDate: '2024-07-23T05:23:01.611Z',
        status: 'redeemed',
    },
    {
        redemptionId: '02809259',
        user: {
            user_image: 'https://lh3.googleusercontent.com/a/ACg8ocILL-CzUE_bnmw1KIkRG_471jgnNjFB-yYegaQFiXSrhg=s96-c',
            displayname: 'jessica_hernandez',
            nicename: 'jessica_hernandez',
        },
        name: 'Samsung',
        type: 'voucher',
        quantity: 1,
        price: 8300,
        purchasedDate: '2024-07-23T05:23:01.611Z',
        voucherCode: '12145663',
        expiryDate: '2024-07-23T05:23:01.611Z',
        status: 'redeemed',
    },
    {
        redemptionId: '02809260',
        user: {
            user_image: 'https://lh3.googleusercontent.com/a/ACg8ocILL-CzUE_bnmw1KIkRG_471jgnNjFB-yYegaQFiXSrhg=s96-c',
            displayname: 'mark_rodriguez',
            nicename: 'mark_rodriguez',
        },
        name: 'UI/UX Design',
        type: 'voucher',
        quantity: 1,
        price: 4300,
        purchasedDate: '2024-07-23T05:23:01.611Z',
        voucherCode: '12145664',
        expiryDate: '2024-07-23T05:23:01.611Z',
        status: 'redeemed',
    },
    {
        redemptionId: '02809261',
        user: {
            user_image: 'https://lh3.googleusercontent.com/a/ACg8ocILL-CzUE_bnmw1KIkRG_471jgnNjFB-yYegaQFiXSrhg=s96-c',
            displayname: 'linda_lee',
            nicename: 'linda_lee',
        },
        name: 'Project Management',
        type: 'voucher',
        quantity: 1,
        price: 8300,
        purchasedDate: '2024-07-23T05:23:01.611Z',
        voucherCode: '12145665',
        expiryDate: '2024-07-23T05:23:01.611Z',
        status: 'redeemed',
    },
];
