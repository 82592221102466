"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_query_1 = require("@tanstack/react-query");
var recoil_1 = require("recoil");
var queryClient = new react_query_1.QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    },
});
/**
 * Root Provider For the React apps
 */
function RootProviders(_a) {
    var children = _a.children;
    return ((0, jsx_runtime_1.jsx)(react_query_1.QueryClientProvider, { client: queryClient, children: (0, jsx_runtime_1.jsx)(recoil_1.RecoilRoot, { children: children }) }));
}
exports.default = RootProviders;
