"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var molecules_1 = require("@/components/molecules");
// ✔ create activity card user info component
/**
 * ### Activity Card User Info Component ###
 * @description This component can render activity card user info component.
 * @param { ActivityCardUserInfoProps } props - What is activity card user info component property?
 * @example
 * <ActivityCardUserInfo
 *  user={USER}
 *  community={COMMUNITY}
 *  timestamp={TIMESTAMP}
 * />
 * @version 1.0.0
 * @author Wunna
 */
function ActivityCardUserInfo(_a) {
    var _b = _a.user, userId = _b._id, userFullName = _b.name, userProfile = _b.profile, userBadges = _b.badges, userLevel = _b.level, role = _b.role, _c = _a.community, communityId = _c._id, communityName = _c.name, isEmoji = _a.isEmoji, timestamp = _a.timestamp;
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-mt-2 tw-flex tw-gap-2 tw-text-sm', children: [(0, jsx_runtime_1.jsx)("img", { className: "tw-relative tw-z-10 tw-size-10 ".concat(isEmoji ? '' : 'tw-rounded-full'), src: userProfile }), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-flex-col', children: [(0, jsx_runtime_1.jsx)("p", { className: 'tw-cursor-pointer tw-text-text-4', style: { color: role === null || role === void 0 ? void 0 : role.color }, children: (role === null || role === void 0 ? void 0 : role.name) || communityName }), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-flex-wrap tw-items-center tw-gap-2', children: [(0, jsx_runtime_1.jsx)("p", { className: 'tw-cursor-pointer tw-whitespace-nowrap tw-text-text-1', children: userFullName }), (0, jsx_runtime_1.jsx)(molecules_1.User.Badges, { badges: userBadges }), (0, jsx_runtime_1.jsx)(molecules_1.User.Level, __assign({}, userLevel)), (0, jsx_runtime_1.jsx)("p", { children: new Date(timestamp).toLocaleTimeString('en', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true,
                                }) })] })] })] }));
}
exports.default = ActivityCardUserInfo;
