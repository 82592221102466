"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableRow = exports.TableHeader = exports.TableHead = exports.TableFooter = exports.TableCell = exports.TableCaption = exports.TableBody = exports.Table = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var React = __importStar(require("react"));
var utils_1 = require("../../lib/utils");
var Table = React.forwardRef(function (_a, ref) {
    var className = _a.className, containerHeight = _a.containerHeight, props = __rest(_a, ["className", "containerHeight"]);
    return ((0, jsx_runtime_1.jsx)("div", { className: (0, utils_1.cn)('tw-relative tw-w-full tw-overflow-auto'), style: {
            height: containerHeight,
        }, children: (0, jsx_runtime_1.jsx)("table", __assign({ ref: ref, className: (0, utils_1.cn)('tw-w-full tw-caption-bottom tw-text-sm', className) }, props)) }));
});
exports.Table = Table;
Table.displayName = 'Table';
var TableHeader = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (0, jsx_runtime_1.jsx)("thead", __assign({ ref: ref, className: (0, utils_1.cn)('tw-relative', className) }, props));
});
exports.TableHeader = TableHeader;
TableHeader.displayName = 'TableHeader';
var TableBody = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (0, jsx_runtime_1.jsx)("tbody", __assign({ ref: ref, className: (0, utils_1.cn)('tw-relative', className) }, props));
});
exports.TableBody = TableBody;
TableBody.displayName = 'TableBody';
var TableFooter = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)("tfoot", __assign({ ref: ref, className: (0, utils_1.cn)('tw-bg-background-2 tw-font-medium tw-text-text-1', className) }, props)));
});
exports.TableFooter = TableFooter;
TableFooter.displayName = 'TableFooter';
var TableRow = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)("tr", __assign({ ref: ref, className: (0, utils_1.cn)('tw-border-b tw-border-divider-lines-1 tw-text-text-1 tw-transition-all tw-duration-100 tw-ease-in-out hover:tw-bg-background-2', className) }, props)));
});
exports.TableRow = TableRow;
TableRow.displayName = 'TableRow';
var TableHead = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)("th", __assign({ ref: ref, className: (0, utils_1.cn)('tw-[&:has([role=checkbox])]:pr-0 tw-h-12 tw-px-4 tw-text-left tw-align-middle tw-font-bold tw-text-text-1', className) }, props)));
});
exports.TableHead = TableHead;
TableHead.displayName = 'TableHead';
var TableCell = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)("td", __assign({ ref: ref, className: (0, utils_1.cn)('tw-[&:has([role=checkbox])]:pr-0 tw-p-4 tw-align-middle', className) }, props)));
});
exports.TableCell = TableCell;
TableCell.displayName = 'TableCell';
var TableCaption = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)("caption", __assign({ ref: ref, className: (0, utils_1.cn)('tw-mt-4 tw-text-sm tw-text-text-1', className) }, props)));
});
exports.TableCaption = TableCaption;
TableCaption.displayName = 'TableCaption';
