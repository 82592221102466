"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddNewGraphValidationSchema = exports.defaultAddNewGraphForm = exports.metricTwoOptions = exports.metricOptions = exports.sizeOptions = exports.graphTypeOptions = void 0;
var yup = __importStar(require("yup"));
exports.graphTypeOptions = [
    {
        value: 'count',
        label: 'Count',
    },
    {
        value: 'line',
        label: 'Line',
    },
    {
        value: 'bar',
        label: 'Bar',
    },
    {
        value: 'doughnut',
        label: 'Doughnut',
    },
    {
        value: 'table',
        label: 'Table',
    },
];
exports.sizeOptions = [
    {
        value: 'regular',
        label: 'Regular',
    },
    {
        value: 'full-screen',
        label: 'Full Screen',
    },
];
exports.metricOptions = [
    {
        value: 'message-sent',
        label: 'Message Sent',
    },
    {
        value: 'total-members',
        label: 'Total members',
    },
];
exports.metricTwoOptions = [
    {
        value: '-',
        label: '-',
    },
];
exports.defaultAddNewGraphForm = {
    title: '',
    description: '',
    type: 'line',
    size: 'regular',
    metric: 'message-sent',
    metric2: {
        enabled: '',
        value: '-',
    },
};
exports.AddNewGraphValidationSchema = yup.object().shape({
    title: yup.string(),
    description: yup.string(),
    type: yup.string(),
    size: yup.string(),
    metric: yup.string(),
    metric2: yup.string(),
});
