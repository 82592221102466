"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var General_1 = require("@/components/molecules/icons/General");
var general_hooks_1 = require("@/lib/hooks/general-hooks");
var recoil_1 = require("recoil");
var utils_1 = require("../../../../lib/utils");
var analytic_dashboard_state_1 = require("../../../../providers/recoil/analytic-dashboard-state");
function Sidebar() {
    var _a = (0, recoil_1.useRecoilState)(analytic_dashboard_state_1.DashboardsState), dashboards = _a[0], setDashboards = _a[1];
    var _b = (0, recoil_1.useRecoilState)(analytic_dashboard_state_1.SelectedDashboardState), selectedDashboard = _b[0], setSelectedDashboard = _b[1];
    var _c = (0, recoil_1.useRecoilState)(analytic_dashboard_state_1.AddDashboardDisableState), addDashboardDisabled = _c[0], setAddDashboardDisabled = _c[1];
    var width = (0, general_hooks_1.useWindowSize)().width;
    var handleAddDashboard = function () {
        setDashboards(function (prev) { return __spreadArray(__spreadArray([], prev, true), [
            {
                id: '',
                name: 'New Dashboard',
            },
        ], false); });
        setSelectedDashboard({
            name: 'New Dashboard',
            show: true,
        });
        setAddDashboardDisabled(true);
    };
    return ((0, jsx_runtime_1.jsxs)("aside", { className: (0, utils_1.cn)('block tw-sticky tw-top-0 tw-w-full md:tw-w-[320px]', width < 768 && selectedDashboard.show ? 'tw-hidden' : 'tw-block'), children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-justify-between', children: [(0, jsx_runtime_1.jsx)("h1", { className: 'tw-text-base tw-font-bold tw-text-text-1', children: "Dashboards" }), (0, jsx_runtime_1.jsx)("button", { onClick: handleAddDashboard, disabled: addDashboardDisabled, className: (0, utils_1.cn)(addDashboardDisabled ? 'disabled:tw-cursor-not-allowed' : 'tw-cursor-pointer'), children: (0, jsx_runtime_1.jsx)(General_1.AddCircleIcon, {}) })] }), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-mt-4 tw-flex tw-flex-col tw-items-start tw-justify-start', children: [dashboards.map(function (dash) {
                        return ((0, jsx_runtime_1.jsx)("button", { onClick: function () {
                                setSelectedDashboard({
                                    name: dash.name,
                                    show: true,
                                });
                            }, className: (0, utils_1.cn)('tw-mb-1 tw-h-[26px] tw-w-full tw-cursor-pointer tw-rounded tw-px-6 tw-py-1 tw-text-left', selectedDashboard.name === dash.name ? 'tw-bg-background-4' : ''), children: dash.name }, dash.id));
                    }), (0, jsx_runtime_1.jsx)("hr", { className: 'tw-my-4 tw-w-full tw-border tw-border-divider-lines-1' }), (0, jsx_runtime_1.jsx)("h2", { className: 'tw-text-sm tw-text-text-4', children: "Add and customise analytics dashboards" })] })] }));
}
exports.default = Sidebar;
