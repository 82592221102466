"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var server_logo_render_1 = require("@/helpers/server-logo-render");
var lucide_react_1 = require("lucide-react");
var angular_bridge_1 = require("../../../../helpers/angular-bridge");
var ServerPopup = function (_a) {
    var servers = _a.servers, activeServer = _a.activeServer, isCnameServer = _a.isCnameServer, serversNotification = _a.serversNotification, onServerClick = _a.onServerClick;
    if ([!servers, servers && servers.length == 0, isCnameServer].includes(true))
        return null;
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-absolute tw-left-0 tw-top-0 tw-z-50 tw-flex tw-flex-col tw-rounded-lg tw-bg-background-5 tw-p-4 tw-text-text-1 tw-shadow-xl tw-transition-all', style: { top: '43px', left: '0px', minWidth: '250px', maxHeight: '300px' }, children: [(0, jsx_runtime_1.jsx)("h3", { className: 'tw-px-2 tw-pb-3 tw-pt-1 tw-text-[14px] tw-font-semibold', children: "Your Communities" }), (0, jsx_runtime_1.jsx)("div", { className: 'tw-flex-1 tw-overflow-y-auto', children: servers &&
                    servers.length > 0 &&
                    servers.map(function (server, index) { return ((0, jsx_runtime_1.jsxs)("div", { onClick: function () {
                            onServerClick(server); // Call the callback function
                            (0, angular_bridge_1.NavigateTo)(server, 'switch');
                        }, className: "tw-flex tw-cursor-pointer tw-items-center tw-rounded-lg tw-p-2 last:tw-mb-0 hover:tw-bg-background-6 ".concat(activeServer._id === server._id ? 'tw-bg-background-7' : 'tw-bg-background-5'), children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-relative', children: [serversNotification[server._id] && ((0, jsx_runtime_1.jsx)("div", { className: 'tw-absolute -tw-right-1.5 -tw-top-1.5 tw-z-20 tw-flex tw-h-3.5 tw-min-w-3.5 tw-items-center tw-justify-center tw-rounded-full tw-bg-error-2 tw-px-1.5 tw-text-[8px]', children: 
                                        // @ts-ignore // Object is possibly 'undefined'. ( solution - undefined can compare with number because undefined mean 0 )
                                        serversNotification[server === null || server === void 0 ? void 0 : server._id].count > 99 ? '99+' : serversNotification[server === null || server === void 0 ? void 0 : server._id].count })), (0, server_logo_render_1.RenderServerImageOrInitial)(server, false)] }), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-ml-3', children: [(0, jsx_runtime_1.jsx)("div", { className: 'tw-text-[12px] tw-font-semibold', children: server.name }), server.url && (0, jsx_runtime_1.jsx)("div", { className: 'tw-text-sm', children: server.url })] })] }, index)); }) }), !isCnameServer && ((0, jsx_runtime_1.jsxs)("div", { onClick: function () {
                    (0, angular_bridge_1.NavigateTo)('create', 'main');
                }, className: 'tw-mt-4 tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-gap-2 tw-rounded-lg tw-border tw-border-background-3 tw-bg-background-3 tw-px-4 tw-py-0', children: [(0, jsx_runtime_1.jsx)("div", { className: 'tw-flex', children: (0, jsx_runtime_1.jsx)("div", { className: 'tw-mb-[1px] tw-text-[14px] tw-font-[500] tw-text-text-1', children: "Create Community" }) }), (0, jsx_runtime_1.jsx)("div", { className: 'tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded tw-bg-background-3', children: (0, jsx_runtime_1.jsx)("div", { className: 'p-3 tw-rounded-full tw-bg-text-1', children: (0, jsx_runtime_1.jsx)(lucide_react_1.Plus, { className: 'tw-h-4 tw-w-4 tw-text-background-6' }) }) })] }))] }));
};
exports.default = ServerPopup;
