"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userFeaturesListContext = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
/* IMPORT CORE MODULES */
var react_1 = require("react");
/** The context of the features list */
var FeaturesListContext = (0, react_1.createContext)({
    events: { title: 'Events', enabled: false },
    threads: { title: 'Threads', enabled: false },
    delete_user: { title: 'Delete User', enabled: false },
    personal_chart: { title: 'Personal Chart', enabled: false },
    custom_desktop_app: { title: 'Custom Desktop App', enabled: false },
    custom_mobile_app: { title: 'Custom Mobile App', enabled: false },
    custom_leaderboard: { title: 'Custom Leaderboard', enabled: false },
    activity_drilldown: { title: 'Activity Drilldown', enabled: false },
    custom_email: { title: 'Custom Email', enabled: false },
    hubspot_integration: { title: 'Hubspot Integration', enabled: false },
    paragon_integration: { title: 'Integrations', enabled: false },
    analytics_dashboard: { title: 'Analytics Dashboard', enabled: false },
    redemption_store: { title: 'Redemption Store', enabled: false },
    rewards_management: { title: 'Rewards Management', enabled: false },
});
/**
 * #### Use Features List Context Hook ####
 * @description This hook can use for the features list context.
 * @returns { FeaturesListContextValue } Features list context
 *
 * @example
 * ```typescriptreact
 * import { userFeaturesListContext } from '@/providers/context';
 *
 * // in react component
 * const featuresListContext = userFeaturesListContext();
 * ```
 *
 * @version 1.0.0
 * @since 10.3.0
 * @author Wunna
 */
function userFeaturesListContext() {
    var featuresListContext = (0, react_1.useContext)(FeaturesListContext);
    if (!featuresListContext) {
        throw new Error('"userFeaturesListContext" must be used within a "FeaturesListContextProvider"');
    }
    return featuresListContext;
}
exports.userFeaturesListContext = userFeaturesListContext;
/**
 * #### Features List Context Provider ####
 * @description This provider can provide features list context.
 * @param { FeaturesListContextProviderProps } props - What is the properties of the features list context provider?
 * @returns { React.ReactNode } Features list context provider
 *
 * @example
 * ```
 * import { FeaturesListContextProvider } from '@/providers/context';
 *
 * <FeaturesListContextProvider>
 *  {OTHER_CHILDREN}
 * </FeaturesListContextProvider>
 * ```
 *
 * @version 1.0.0
 * @since 10.3.0
 * @author Wunna
 */
function FeaturesListContextProvider(_a) {
    var featuresList = _a.featuresList, children = _a.children;
    return (0, jsx_runtime_1.jsx)(FeaturesListContext.Provider, { value: featuresList, children: children });
}
exports.default = FeaturesListContextProvider;
