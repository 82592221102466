"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var utils_1 = require("@/lib/utils");
// TO_ASK: why import css here?
require("../../../../styles/globals.css");
function MentionUser(_a) {
    var sameIndex = _a.sameIndex, user = _a.user, setTheUser = _a.setTheUser, getUserRoleAttr = _a.getUserRoleAttr, getUserRoleColor = _a.getUserRoleColor;
    var handleClick = function (user) {
        setTheUser({ user: user });
    };
    var roleName = getUserRoleAttr({ user: user });
    var colorVal = '';
    var roleColor = getUserRoleColor({ user: user });
    var regex = /var\([^)]+\)/;
    var match = roleColor === null || roleColor === void 0 ? void 0 : roleColor.match(regex);
    if (match) {
        colorVal = match[0];
    }
    else {
        colorVal = '';
    }
    return ((0, jsx_runtime_1.jsxs)("li", { id: user.id.toString(), onClick: function () { return handleClick(user); }, className: (0, utils_1.cn)("tw-mx-2 tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-sm tw-p-2 hover:tw-bg-background-3", sameIndex ? 'mentionUser tw-bg-background-3' : ''), children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-[10px]', children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-relative', children: [(0, jsx_runtime_1.jsx)("img", { src: user.profileCropImg ? user.profileCropImg : user.avatar, alt: user.displayname || user.nicename, className: 'tw-h-[35px] tw-w-[35px] tw-rounded-full tw-object-cover' }), (0, jsx_runtime_1.jsx)("div", { className: statusClasses, children: (0, jsx_runtime_1.jsx)("div", { className: (0, utils_1.cn)('tw-h-2 tw-w-2 tw-rounded-full', user.sid.length > 0 || user.isOnline ? 'tw-bg-success-1' : 'tw-bg-error-2') }) })] }), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-text-sm', children: [(0, jsx_runtime_1.jsx)("p", { className: 'tw-leading-1 tw-font-semibold', style: { color: colorVal }, children: roleName }), (0, jsx_runtime_1.jsx)("p", { className: 'tw-truncate125 tw-font-bold tw-text-text-1', children: user.displayname || user.nicename })] })] }), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-truncate125 tw-text-xs tw-text-text-5', children: ["@", user.nicename] })] }));
}
var statusClasses = 'tw-bg-background-1 tw-w-4 tw-h-4 tw-rounded-full tw-absolute tw-right-0 tw-bottom-0 tw-flex tw-items-center tw-justify-center';
exports.default = MentionUser;
