"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Calendar = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var lucide_react_1 = require("lucide-react");
var react_day_picker_1 = require("react-day-picker");
var utils_1 = require("../../lib/utils");
var button_1 = require("./button");
function Calendar(_a) {
    var className = _a.className, classNames = _a.classNames, _b = _a.showOutsideDays, showOutsideDays = _b === void 0 ? true : _b, props = __rest(_a, ["className", "classNames", "showOutsideDays"]);
    return ((0, jsx_runtime_1.jsx)(react_day_picker_1.DayPicker, __assign({ showOutsideDays: showOutsideDays, className: (0, utils_1.cn)('tw-p-3', className), classNames: __assign({ months: 'tw-flex tw-flex-col sm:tw-flex-row tw-space-y-4 sm:tw-space-x-4 sm:tw-space-y-0', month: 'tw-space-y-4', caption: 'tw-flex tw-justify-center tw-pt-1 tw-relative tw-items-center', caption_label: 'tw-text-sm tw-font-medium', nav: 'tw-space-x-1 tw-flex tw-items-center', nav_button: (0, utils_1.cn)((0, button_1.buttonVariants)({ variant: 'outline' }), 'tw-h-7 tw-w-7 tw-bg-accent-1 tw-p-0 tw-opacity-70 hover:tw-opacity-100 hover:tw-bg-accent-1/60'), nav_button_previous: 'tw-absolute tw-left-1', nav_button_next: 'tw-absolute tw-right-1', table: 'tw-w-full tw-border-collapse tw-space-y-1 !tw-bg-background-2', head_row: 'tw-flex', head_cell: '!tw-bg-background-2 tw-rounded-md tw-w-9 tw-font-normal tw-text-[0.8rem]', row: 'tw-flex tw-w-full tw-mt-2', cell: (0, utils_1.cn)('relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent [&:has([aria-selected].day-outside)]:bg-red-500/50 [&:has([aria-selected].day-range-end)]:rounded-r-md', props.mode === 'range'
                ? '[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md'
                : '[&:has([aria-selected])]:rounded-md'), day: (0, utils_1.cn)((0, button_1.buttonVariants)({ variant: 'ghost' }), 'tw-h-9 tw-w-9 tw-p-0 tw-font-normal aria-selected:tw-opacity-100'), day_range_start: 'day-range-start', day_range_end: 'day-range-end', day_selected: 'tw-bg-accent-1 tw-text-text-1 hover:tw-bg-accent-1 focus:tw-bg-accent-1', day_today: 'tw-bg-background-7 tw-opacity-80 tw-text-text-1', day_outside: 'tw-text-text-1 tw-opacity-50', day_disabled: 'tw-text-text-1 tw-opacity-50', day_range_middle: 'aria-selected:tw-bg-accent-1 aria-selected:tw-opacity-50 aria-selected:tw-text-text-1/80', day_hidden: 'tw-invisible' }, classNames), components: {
            IconLeft: function (_a) { return ((0, jsx_runtime_1.jsx)("span", { children: (0, jsx_runtime_1.jsx)(lucide_react_1.ChevronLeft, { className: 'tw-h-3 tw-w-3' }) })); },
            IconRight: function (_a) { return ((0, jsx_runtime_1.jsx)("span", { children: (0, jsx_runtime_1.jsx)(lucide_react_1.ChevronRight, { className: 'tw-h-3 tw-w-3' }) })); },
        } }, props)));
}
exports.Calendar = Calendar;
Calendar.displayName = 'Calendar';
