"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var utils_1 = require("@/lib/utils");
/**
 * Profile setting pages heading
 * @description This component is used for Displaying Heading section in Profile pages
 */
function SettingsHeader(_a) {
    var title = _a.title, children = _a.children, className = _a.className;
    return ((0, jsx_runtime_1.jsxs)("div", { className: (0, utils_1.cn)('tw-mb-3 tw-border-b tw-border-divider-lines-1 tw-pb-3', className), children: [(0, jsx_runtime_1.jsx)("h1", { className: 'tw-text-[16px] tw-font-bold tw-text-text-1', children: title }), children] }));
}
exports.default = SettingsHeader;
