"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var checkbox_1 = require("@/components/ui/checkbox");
var RoleCheckbox = function (_a) {
    var label = _a.label, labelStyle = _a.labelStyle, id = _a.id, props = __rest(_a, ["label", "labelStyle", "id"]);
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-space-x-2', children: [(0, jsx_runtime_1.jsx)(checkbox_1.Checkbox, __assign({ id: id }, props, { className: '!tw-border-divider-lines-1' })), (0, jsx_runtime_1.jsx)("label", { htmlFor: id, style: labelStyle, className: 'tw-cursor-pointer tw-select-none  tw-text-sm tw-font-medium tw-leading-none peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70', children: label })] }));
};
exports.default = RoleCheckbox;
