"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var utils_1 = require("@/lib/utils");
/** Customisation Label for Input */
function CustomisationLabel(_a) {
    var enabled = _a.enabled, label = _a.label, className = _a.className, description = _a.description;
    return ((0, jsx_runtime_1.jsxs)("div", { className: (0, utils_1.cn)(!enabled && 'tw-pointer-events-none tw-opacity-30', className), children: [(0, jsx_runtime_1.jsx)("h4", { className: 'tw-mb-2 tw-font-bold tw-text-text-1', children: label }), (0, jsx_runtime_1.jsx)("p", { className: 'tw-mt-2 tw-text-text-4', children: description })] }));
}
exports.default = CustomisationLabel;
