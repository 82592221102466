"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResizableContext = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
/** The context of the resizable */
var ResizableContext = (0, react_1.createContext)({
    styleVariableKey: '',
    mouseDownHandler: function (event) { },
});
// ✔ create use resizable context hook
/**
 * #### Use Resizable Context Hook ####
 * @description This hook can use for the resizable context.
 * @returns { ResizableContextValue } resizable context
 *
 * @example
 * ```typescriptreact
 * import { userResizableContext } from '@/providers/context';
 *
 * // in react component
 * const resizableContext = userResizableContext();
 * ```
 *
 * @version 1.0.0
 * @since 10.3.0
 * @author Wunna
 */
function useResizableContext() {
    var resizableContext = (0, react_1.useContext)(ResizableContext);
    if (!resizableContext) {
        throw new Error('"userResizableContext" must be used within a "ResizableContextProvider"');
    }
    return resizableContext;
}
exports.useResizableContext = useResizableContext;
/* CONSTANTS */
var startPoint = 0;
var previousWidthValue = 0;
var currentWidthValue = 0;
// ✔ create resizable context provider
/**
 * #### Resizable Context Provider ####
 * @description This provider can provide resizable context.
 * @param { ResizableContextProviderProps } props - What is the properties of the resizable context provider?
 * @returns { React.ReactNode } resizable context provider
 *
 * @example
 * ```
 * import { ResizableContextProvider } from '@/providers/context';
 *
 * <ResizableContextProvider>
 *  {OTHER_CHILDREN}
 * </ResizableContextProvider>
 * ```
 *
 * @version 1.0.0
 * @since 10.3.0
 * @author Wunna
 */
function ResizableContextProvider(_a) {
    var containerId = _a.containerId, persistenceKey = _a.persistenceKey, styleVariableKey = _a.styleVariableKey, minWidth = _a.minWidth, maxWidth = _a.maxWidth, defaultWidth = _a.defaultWidth, children = _a.children;
    // calculate current width when data is persistence
    var currentWidth = (0, react_1.useMemo)(function () {
        if (!persistenceKey)
            return defaultWidth;
        var persistenceValue = localStorage.getItem(persistenceKey);
        var persistenceValueJson = persistenceValue
            ? JSON.parse(persistenceValue)
            : undefined;
        if (!persistenceValueJson)
            return defaultWidth;
        if (persistenceValueJson.expire > Date.now())
            return persistenceValueJson.width;
        localStorage.removeItem(persistenceKey);
        return defaultWidth;
    }, [defaultWidth, persistenceKey]);
    var _b = (0, react_1.useState)(currentWidth), width = _b[0], setWidth = _b[1];
    // ✔ create mouse move handler function
    /**
     * #### Mouse Move Handler Function ####
     * @description This function can handle mouse movement on window.
     * @param { MouseEvent } event - What is mouse event of the window?
     * @returns { void } Nothing return
     *
     * @example
     * window.addEventListener('mousemove', mouseMoveHandler);
     *
     * @version 1.0.0
     * @since 1.0.0
     * @author Wunna
     */
    var mouseMoveHandler = function (event) {
        if (startPoint === 0)
            return;
        // calculate max width from notification activity container
        var element = document.getElementById(containerId);
        var maxContainerWidth = element.clientWidth - maxWidth;
        // calculate container width base on mouse move movement
        setWidth(function (previousWidth) {
            if (previousWidthValue === 0)
                previousWidthValue = previousWidth;
            var diff = startPoint - previousWidthValue;
            var currentWidth = event.clientX - diff;
            if (currentWidth > maxContainerWidth) {
                currentWidthValue = maxContainerWidth;
                return maxContainerWidth;
            }
            if (currentWidth < minWidth) {
                currentWidthValue = minWidth;
                return minWidth;
            }
            currentWidthValue = currentWidth;
            return currentWidth;
        });
    };
    // ✔ create mouse up handler function
    /**
     * #### Mouse Up Handler Function ####
     * @description This function can handle mouse up ( mean mouse is not hold anymore ) on window.
     * @returns { void } Nothing return
     *
     * @example
     * window.addEventListener('mouseup', mouseUpHandler);
     *
     * @version 1.0.0
     * @since 1.0.0
     * @author Wunna
     */
    var mouseUpHandler = function () {
        // reset start point and previous with value
        startPoint = 0;
        previousWidthValue = 0;
        // if it has persistence key, store current width value in local storage for 24 hours
        if (persistenceKey) {
            localStorage.setItem(persistenceKey, JSON.stringify({ width: currentWidthValue, expire: Date.now() + 24 * 60 * 60 * 1000 }));
        }
        // remove mouse up and move event listeners
        window.removeEventListener('mousemove', mouseMoveHandler);
        window.removeEventListener('mouseup', mouseUpHandler);
    };
    // ✔ create mouse down handler function
    /**
     * #### Mouse Down Handler Function ####
     * @description This function can handle mouse down ( mean mouse is clicked and hold ) on react component.
     * @param { React.MouseEvent<HTMLDivElement, MouseEvent> } event - What is the mouse event of the react component?
     * @returns { void } Nothing return
     *
     * @example
     * <div onMouseDown={mouseDownHandler}></div>
     *
     * @version 1.0.0
     * @since 1.0.0
     * @author Wunna
     */
    var mouseDownHandler = function (event) {
        // when user click on resizable bar, add mouse up and mouse move listeners and store start point
        window.addEventListener('mouseup', mouseUpHandler);
        window.addEventListener('mousemove', mouseMoveHandler);
        startPoint = event.clientX;
    };
    /* Width change effect */
    (0, react_1.useEffect)(function () {
        // set root style every time width change
        var root = document.querySelector(':root');
        if (root)
            root.style.setProperty("--".concat(styleVariableKey), "".concat(width, "px"));
    }, [width]);
    return ((0, jsx_runtime_1.jsx)(ResizableContext.Provider, { value: {
            styleVariableKey: styleVariableKey,
            mouseDownHandler: mouseDownHandler,
        }, children: children }));
}
exports.default = ResizableContextProvider;
