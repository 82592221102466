const React = require('react')
const ReactDOM = require('react-dom/client')
const isPlainObject = require('lodash/isPlainObject')
const isEqual = require('lodash/isEqual')

/**
 * Angularize Children Elements (Use this one)
 * @param {*} Component - React Component
 * @param {*} componentName - Angular Directive Name
 * @param {*} angularApp - Angular app
 * @param {*} bindings - Props passing
 * @description Please use this function for wrapping Angular elemnts with React Wrapper
 * @example Example can be seen at `chat-container.tsx`
 * @returns
 */
export function angularizeChildren(Component, componentName, angularApp, bindings) {
  bindings = bindings || {}
  if (typeof window === 'undefined' || typeof angularApp === 'undefined') return

  angularApp.component(componentName, {
    bindings: bindings,
    transclude: true,
    controller: [
      '$element',
      '$transclude',
      function ($element, $transclude) {
        const element = $element[0]
        const root = ReactDOM.createRoot(element)
        const previous = {}
        let reactProps = {}

        // this.$onInit = () => {
        //   for (let bindingKey of Object.keys(bindings)) {
        //     if (/^data[A-Z]/.test(bindingKey)) {
        //       console.warn(
        //         `'${bindingKey}' binding for ${componentName} component will be undefined because AngularJS ignores attributes starting with data-`
        //       )
        //     }

        //     if (bindings[bindingKey] === '=') {
        //       // @ts-ignore
        //       previous[bindingKey] = window.angular.copy(this[bindingKey])
        //     }
        //   }
        // }

        // this.$doCheck = () => {
        //   for (let previousKey of Object.keys(previous)) {
        //     if (!equals(this[previousKey], previous[previousKey])) {
        //       this.$onChanges()
        //       // @ts-ignore
        //       previous[previousKey] = window.angular.copy(this[previousKey])
        //       return
        //     }
        //   }
        // }

        this.$postLink = () => {
          $transclude((clone) => {
            const angularChild = document.createDocumentFragment()
            angularChild.append(...clone)
            reactProps = { ...this, angularChild }
            renderComponent()
          })
        }

        this.$onChanges = (changesObj) => {
          Object.keys(changesObj).forEach((prop) => {
            reactProps[prop] = this[prop]
          })

          // console.log('Changes detected in bindings:', changesObj)

          renderComponent() // Re-render with updated props
        }

        const renderComponent = () => {
          root.render(React.createElement(Component, reactProps))
        }

        this.$onDestroy = () => {
          root.unmount()
        }
      },
    ],
  })
}

function equals(o1, o2) {
  // Compare plain objects without equality check that angular.equals does
  if (isPlainObject(o1) && isPlainObject(o2)) {
    return isEqual(o1, o2)
  }
  // @ts-ignore
  return window.angular.equals(o1, o2)
}
