"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
/**
 * ### Activity Card Container ###
 * @description This layout can wrap all activity card container for same ui layout.
 * @param { ActivityCardContainerProps } props - What is activity card container properties?
 * @example
 * ```
 * <ActivityCardContainer
 *   id={ID}
 *   onClick={CLICK_HANDLER_FUNCTION}
 * >
 *   [OTHER_CODES]
 * </ActivityCardContainer>
 * ```
 * @version 1.0.1
 * @author Wunna
 */
function ActivityCardContainer(_a) {
    var id = _a.id, children = _a.children, onOpenNotification = _a.onOpenNotification;
    return ((0, jsx_runtime_1.jsx)("div", { id: id, className: 'tw-group tw-cursor-pointer tw-border-b-2 tw-border-solid tw-border-divider-lines-1 tw-bg-background-4 tw-p-4 hover:tw-bg-background-2', onClick: onOpenNotification, children: children }));
}
exports.default = ActivityCardContainer;
