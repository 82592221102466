"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoBox = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var General_1 = require("@/components/molecules/icons/General");
/** Grey Info Box */
function InfoBox(_a) {
    var children = _a.children;
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-my-4 tw-flex tw-items-center tw-gap-2 tw-rounded-[4px] tw-bg-background-7 tw-p-2', children: [(0, jsx_runtime_1.jsx)("div", { className: 'tw-flex-1', children: (0, jsx_runtime_1.jsx)(General_1.SolidInfoCircleSVG, {}) }), children] }));
}
exports.InfoBox = InfoBox;
