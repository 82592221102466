"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileProfileDrawerState = void 0;
var recoil_1 = require("recoil");
/**
 * Mobile Profile setting Drawer
 */
exports.MobileProfileDrawerState = (0, recoil_1.atom)({
    key: 'MobileProfileDrawerState',
    default: false,
});
