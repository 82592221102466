"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var button_1 = require("@/components/ui/button");
var utils_1 = require("@/lib/utils");
var translations_helper_1 = require("../../../../../helpers/translations-helper");
/**
 * UnSaved Changes Modal that will be popuped when typing in input fields
 * @example sample usage can be found at `<ProfileForm.tsx/>`
 */
function UnsavedChangesModal(_a) {
    var open = _a.open, text = _a.text, handleReset = _a.handleReset, handleSave = _a.handleSave, handlePreviewStoreClick = _a.handlePreviewStoreClick, saveDisabled = _a.saveDisabled, previewStore = _a.previewStore;
    var translations = (0, translations_helper_1.useTranslations)().translations;
    return ((0, jsx_runtime_1.jsxs)("div", { role: 'presentation', "aria-label": 'unsaved changes modal', className: (0, utils_1.cn)(open ? 'tw-translate-y-0 tw-opacity-100' : 'tw-translate-y-[200%] tw-opacity-0', 'tw-fixed tw-bottom-4 tw-left-[50%] tw-z-[999] tw-flex tw-w-auto tw-translate-x-[-50%] tw-transform-cpu tw-items-center tw-justify-between tw-rounded tw-bg-background-3 tw-px-4 tw-py-3 tw-text-text-1 tw-shadow-md tw-transition-all tw-duration-500 md:tw-w-[500px]'), children: [previewStore ? ((0, jsx_runtime_1.jsx)(button_1.Button, { onClick: handlePreviewStoreClick, size: 'sm', children: "Preview Store" })) : ((0, jsx_runtime_1.jsx)("p", { className: 'tw-hidden tw-text-xs md:tw-block', children: text || translations.Be_Careful_Description })), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-1', children: [(0, jsx_runtime_1.jsx)(button_1.Button, { onClick: handleReset, variant: 'link', size: 'sm', children: translations.Reset }), (0, jsx_runtime_1.jsx)(button_1.Button, { disabled: saveDisabled, onClick: handleSave, variant: 'success', size: 'sm', children: translations.Save_Changes })] })] }));
}
exports.default = UnsavedChangesModal;
