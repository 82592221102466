"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var tooltip_1 = require("@/components/ui/tooltip");
var utils_1 = require("@/lib/utils");
/**
 * Custom Tooltip component
 */
function CustomTooltip(_a) {
    var children = _a.children, text = _a.text, side = _a.side, onClick = _a.onClick, disabled = _a.disabled, className = _a.className;
    return ((0, jsx_runtime_1.jsx)(tooltip_1.TooltipProvider, { children: (0, jsx_runtime_1.jsxs)(tooltip_1.Tooltip, { delayDuration: 50, children: [(0, jsx_runtime_1.jsx)(tooltip_1.TooltipTrigger, { onClick: onClick, "aria-label": 'tooltip', disabled: disabled, role: 'button', className: (0, utils_1.cn)('disabled:tw-cursor-not-allowed', className), children: children }), !disabled && (0, jsx_runtime_1.jsx)(tooltip_1.TooltipContent, { side: side, children: text })] }) }));
}
exports.default = CustomTooltip;
