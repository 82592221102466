"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialogTrigger = exports.DialogTitle = exports.DialogHeader = exports.DialogFooter = exports.DialogDescription = exports.DialogContent = exports.Dialog = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var DialogPrimitive = __importStar(require("@radix-ui/react-dialog"));
var lucide_react_1 = require("lucide-react");
var React = __importStar(require("react"));
var utils_1 = require("../../lib/utils");
var Dialog = DialogPrimitive.Root;
exports.Dialog = Dialog;
var DialogTrigger = DialogPrimitive.Trigger;
exports.DialogTrigger = DialogTrigger;
var DialogPortal = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(DialogPrimitive.Portal, __assign({ className: (0, utils_1.cn)(className) }, props)));
};
DialogPortal.displayName = DialogPrimitive.Portal.displayName;
var DialogOverlay = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(DialogPrimitive.Overlay, __assign({ ref: ref, className: (0, utils_1.cn)('tw-z-50 tw-fixed tw-inset-0 tw-bg-black/80 tw-backdrop-blur-sm data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0', className) }, props)));
});
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;
var DialogContent = React.forwardRef(function (_a, ref) {
    var className = _a.className, children = _a.children, hideClose = _a.hideClose, props = __rest(_a, ["className", "children", "hideClose"]);
    return ((0, jsx_runtime_1.jsxs)(DialogPortal, { className: 'px-3', children: [(0, jsx_runtime_1.jsx)(DialogOverlay, {}), (0, jsx_runtime_1.jsxs)(DialogPrimitive.Content, __assign({ ref: ref, className: (0, utils_1.cn)('tw-fixed tw-left-[50%] tw-top-[50%] tw-z-50 tw-grid tw-w-[90%] tw-max-w-lg tw-translate-x-[-50%] tw-translate-y-[-50%] tw-gap-4 tw-rounded-md tw-bg-background-4 tw-p-6 tw-text-text-1 tw-shadow-lg tw-duration-200 data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0 data-[state=closed]:tw-zoom-out-95 data-[state=open]:tw-zoom-in-95 data-[state=closed]:tw-slide-out-to-left-1/2 data-[state=closed]:tw-slide-out-to-top-[48%] data-[state=open]:tw-slide-in-from-left-1/2 data-[state=open]:tw-slide-in-from-top-[48%] md:tw-w-full', className) }, props, { children: [children, !hideClose ? ((0, jsx_runtime_1.jsxs)(DialogPrimitive.Close, { className: 'tw-absolute tw-right-6 tw-top-6 tw-rounded-sm tw-opacity-70 tw-transition-all tw-duration-300 tw-ease-in-out hover:tw-scale-110 hover:tw-opacity-100 focus:tw-outline-none disabled:tw-pointer-events-none data-[state=open]:tw-bg-accent-1 data-[state=open]:tw-text-customgrey', children: [(0, jsx_runtime_1.jsx)(lucide_react_1.X, { className: 'tw-h-5 tw-w-5' }), (0, jsx_runtime_1.jsx)("span", { className: 'tw-sr-only', children: "Close" })] })) : null] }))] }));
});
exports.DialogContent = DialogContent;
DialogContent.displayName = DialogPrimitive.Content.displayName;
var DialogHeader = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: (0, utils_1.cn)('tw-flex tw-flex-col tw-space-y-1.5 tw-text-center sm:tw-text-left', className) }, props)));
};
exports.DialogHeader = DialogHeader;
DialogHeader.displayName = 'DialogHeader';
var DialogFooter = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: (0, utils_1.cn)('tw-flex tw-justify-end tw-px-6 tw-pb-6 sm:tw-space-x-2', className) }, props)));
};
exports.DialogFooter = DialogFooter;
DialogFooter.displayName = 'DialogFooter';
var DialogTitle = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(DialogPrimitive.Title, __assign({ ref: ref, className: (0, utils_1.cn)('tw-text-lg tw-font-semibold tw-leading-none tw-tracking-tight', className) }, props)));
});
exports.DialogTitle = DialogTitle;
DialogTitle.displayName = DialogPrimitive.Title.displayName;
var DialogDescription = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(DialogPrimitive.Description, __assign({ ref: ref, className: (0, utils_1.cn)('tw-text-sm tw-text-black', className) }, props)));
});
exports.DialogDescription = DialogDescription;
DialogDescription.displayName = DialogPrimitive.Description.displayName;
