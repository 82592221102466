"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var utils_1 = require("@/lib/utils");
/**
 * Single Radio component
 */
function Radio(_a) {
    var name = _a.name, label = _a.label, labelClassesNames = _a.labelClassesNames, checked = _a.checked, value = _a.value, onChange = _a.onChange, disabled = _a.disabled;
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-space-x-2', children: [(0, jsx_runtime_1.jsx)("input", { type: 'radio', value: value, name: name, checked: checked, onChange: function (e) {
                    onChange(e);
                }, className: 'tw-hidden tw-opacity-50 disabled:tw-cursor-not-allowed', disabled: disabled }), (0, jsx_runtime_1.jsxs)("label", { htmlFor: name, className: (0, utils_1.cn)('tw-flex tw-select-none tw-items-center tw-text-text-1', disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer '), children: [(0, jsx_runtime_1.jsx)("div", { className: (0, utils_1.cn)('tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full tw-border hover:tw-border-accent-1', checked ? 'tw-border-accent-1' : 'tw-border-customgrey', disabled ? 'tw-pointer-events-none' : ''), children: (0, jsx_runtime_1.jsx)("div", { className: (0, utils_1.cn)(checked ? 'tw-h-3 tw-w-3 tw-rounded-full tw-border-accent-1 tw-bg-accent-1' : 'tw-hidden', disabled ? 'tw-opacity-50' : '') }) }), label ? ((0, jsx_runtime_1.jsx)("label", { htmlFor: name, className: (0, utils_1.cn)('tw-ml-2 hover:tw-opacity-80', labelClassesNames, disabled ? 'tw-opacity-50' : ''), children: label })) : null] })] }));
}
exports.default = Radio;
