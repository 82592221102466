"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RenderServerImageOrInitial = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var General_1 = require("@/components/molecules/icons/General");
var ImageRounded = function (_a) {
    var image = _a.image, initial = _a.initial, backgroundColor = _a.backgroundColor, isActive = _a.isActive, isMode = _a.isMode, _b = _a.quantity, quantity = _b === void 0 ? 0 : _b;
    var additionalSVG = null;
    if (isActive) {
        if (quantity >= 3) {
            additionalSVG = (0, jsx_runtime_1.jsx)(General_1.ThreeLayersShadowSVG, { isMode: isMode });
        }
        else if (quantity === 2) {
            additionalSVG = (0, jsx_runtime_1.jsx)(General_1.TwoLayersShadowSVG, { isMode: isMode });
        }
    }
    if (image) {
        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("img", { src: image, alt: 'Logo', className: "".concat(isActive ? 'tw-h-[32px] tw-w-[32px] tw-rounded-[5px] !tw-bg-background-6' : 'tw-h-8 tw-w-8 tw-rounded-[7px]', " tw-z-10"), style: { objectFit: 'cover' } }), additionalSVG] }));
    }
    else {
        return ((0, jsx_runtime_1.jsx)("div", { className: "tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-[7px] tw-text-lg tw-font-bold tw-text-text-1 ".concat(!backgroundColor ? 'tw-bg-background-3' : ''), style: { backgroundColor: backgroundColor || undefined }, children: initial }));
    }
};
exports.default = ImageRounded;
var RenderServerImageOrInitial = function (server, isActive, isMode, quantity) {
    var _a;
    var initial = ((_a = server === null || server === void 0 ? void 0 : server.name) === null || _a === void 0 ? void 0 : _a.charAt(0).toUpperCase()) || 'C';
    var backgroundColor = (server === null || server === void 0 ? void 0 : server.color) || null;
    return ((0, jsx_runtime_1.jsx)(ImageRounded, { image: server.image, initial: initial, backgroundColor: backgroundColor, isActive: isActive, isMode: isMode, quantity: quantity }));
};
exports.RenderServerImageOrInitial = RenderServerImageOrInitial;
