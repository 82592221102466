"use strict";
'use client';
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var AccordionItem_1 = require("./AccordionItem");
var AccordionHeader = function (_a) {
    var children = _a.children, _b = _a.as, as = _b === void 0 ? 'button' : _b, _c = _a.className, className = _c === void 0 ? '' : _c, _d = _a.href, href = _d === void 0 ? '' : _d, onClick = _a.onClick;
    var _e = (0, react_1.useContext)(AccordionItem_1.AccordionItemContext), hash = _e.hash, toggle = _e.toggle, items = _e.items, alwaysOpen = _e.alwaysOpen, isActive = _e.isActive;
    var ref = (0, react_1.useRef)(null);
    var TagName = (0, react_1.useMemo)(function () {
        if (as) {
            return as;
        }
        return 'button';
    }, [as]);
    (0, react_1.useEffect)(function () {
        if (isActive && ref && ref.current) {
            toggle();
            var button = ref.current;
            button.setAttribute('aria-expanded', 'true');
            var content = document.querySelector("#".concat(button.getAttribute('aria-controls')));
            if (content) {
                content.style.maxHeight = 'none';
            }
        }
    }, [isActive, toggle]);
    (0, react_1.useEffect)(function () {
        var toggleButton = function (button) {
            var ariaExpanded = button.getAttribute('aria-expanded');
            button.setAttribute('aria-expanded', ariaExpanded === 'false' ? 'true' : 'false');
        };
        var toggleContent = function (content) {
            if (content) {
                var transitionEnd_1 = function () {
                    if (content.style.maxHeight !== '0px') {
                        content.style.maxHeight = 'none';
                    }
                    content.removeEventListener('transitionend', transitionEnd_1);
                };
                content.addEventListener('transitionend', transitionEnd_1);
                if (content.style.maxHeight === '0px') {
                    content.style.maxHeight = content.scrollHeight + 'px';
                }
                else {
                    content.style.maxHeight = content.scrollHeight + 'px';
                    content.style.maxHeight = content.scrollHeight + 'px';
                    content.style.maxHeight = '0px';
                }
            }
        };
        if (ref && ref.current) {
            var button_1 = ref === null || ref === void 0 ? void 0 : ref.current;
            var showAccordion_1 = function (e) {
                var _a;
                // Pervent default
                if (TagName === 'a') {
                    e.preventDefault();
                }
                toggle();
                if (!alwaysOpen) {
                    // Close content already open
                    var buttons = (_a = button_1.parentNode) === null || _a === void 0 ? void 0 : _a.querySelectorAll(":scope > ".concat(TagName, "[aria-expanded='true']"));
                    if (buttons) {
                        buttons.forEach(function (item) {
                            if (item && item !== button_1) {
                                var id = item.id.split('-')[1];
                                items[id](false);
                                toggleButton(item);
                                var content_1 = document.querySelector("#".concat(item.getAttribute('aria-controls')));
                                if (content_1) {
                                    toggleContent(content_1);
                                }
                            }
                        });
                    }
                }
                // Toggle Button
                toggleButton(button_1);
                // Toggle Content
                var content = document.querySelector("#".concat(button_1.getAttribute('aria-controls')));
                toggleContent(content);
                if (onClick) {
                    onClick(e);
                }
            };
            if (button_1) {
                button_1.addEventListener('click', showAccordion_1);
            }
            return function () {
                if (button_1) {
                    button_1.removeEventListener('click', showAccordion_1);
                }
            };
        }
        return function () { };
    }, [TagName, alwaysOpen, items, onClick, toggle]);
    if (TagName === 'a') {
        return ((0, jsx_runtime_1.jsx)("a", { ref: ref, id: "button-".concat(hash), href: href, "aria-expanded": 'false', className: className, "aria-controls": "content-".concat(hash), children: children }));
    }
    return ((0, jsx_runtime_1.jsx)(TagName, { ref: ref, id: "button-".concat(hash), "aria-expanded": 'false', className: className, "aria-controls": "content-".concat(hash), children: children }));
};
exports.default = AccordionHeader;
