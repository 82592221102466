"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.capitalizeFirstLetter = void 0;
/**
 * Capitalize First letters
 * @example
 *  const type = "lorem";
 *  const result = capitalize(type); // Lorem
 */
function capitalizeFirstLetter(str) {
    if (!str)
        return str;
    if (str.length === 0)
        return str;
    if (str.length === 1)
        return str.toUpperCase();
    return str[0].toUpperCase() + str.slice(1);
}
exports.capitalizeFirstLetter = capitalizeFirstLetter;
