"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var General_1 = require("@/components/molecules/icons/General");
var utils_1 = require("@/lib/utils");
var IconMap = {
    whisper: (0, jsx_runtime_1.jsx)(General_1.WhisperIcon, {}),
    announce: (0, jsx_runtime_1.jsx)(General_1.AnnouncementIcon, {}),
    direct: (0, jsx_runtime_1.jsx)(General_1.DMICon, {}),
    open: (0, jsx_runtime_1.jsx)(General_1.HashIcon, {}),
    audio: (0, jsx_runtime_1.jsx)(General_1.AudioIcon, {}),
    video: (0, jsx_runtime_1.jsx)(General_1.VideoIcon, {}),
};
var TextMap = {
    whisper: 'Whisper',
    announce: 'Announcement',
    direct: 'Direct Message',
    open: 'Open',
    audio: 'Audio',
    video: 'Video',
};
function MentionChannel(_a) {
    var sameIndex = _a.sameIndex, channel = _a.channel, setTheForumOption = _a.setTheForumOption;
    var handleClick = function (id) {
        setTheForumOption({ id: id });
    };
    return ((0, jsx_runtime_1.jsxs)("li", { id: channel.id.toString(), onClick: function () { return handleClick(channel.id); }, className: (0, utils_1.cn)("tw-mx-2 tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-sm tw-p-1 hover:tw-bg-background-3", sameIndex ? 'tw-bg-background-3' : ''), children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-3', children: [(0, jsx_runtime_1.jsx)("span", { children: IconMap[channel.channelType] }), (0, jsx_runtime_1.jsx)("h3", { className: 'tw-text-sm tw-font-bold tw-text-text-1', children: channel.title })] }), (0, jsx_runtime_1.jsx)("div", { className: 'tw-truncate125 tw-text-xs tw-text-text-5', children: TextMap[channel.channelType] })] }));
}
exports.default = MentionChannel;
