"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var General_1 = require("@/components/molecules/icons/General");
var button_1 = require("@/components/ui/button");
var dropdown_menu_1 = require("@/components/ui/dropdown-menu");
var utils_1 = require("@/lib/utils");
var react_1 = require("react");
var date_range_picker_1 = require("./date-range-picker");
var items_1 = require("./items");
/**
 * Duration Dropdown Picker
 */
function DurationDropdownPicker(_a) {
    var selected = _a.selected, onChange = _a.onChange, disabled = _a.disabled, side = _a.side, align = _a.align, contentClassNames = _a.contentClassNames;
    var _b = (0, react_1.useState)(''), local = _b[0], setLocal = _b[1];
    var _c = (0, react_1.useState)(), selectedDay = _c[0], setSelectedDay = _c[1];
    (0, react_1.useEffect)(function () {
        if (onChange) {
            onChange(local);
        }
    }, [local]);
    var handleChange = function (val) {
        setLocal(val);
        onChange(local);
    };
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-2', children: [(0, jsx_runtime_1.jsxs)(dropdown_menu_1.DropdownMenu, { children: [(0, jsx_runtime_1.jsxs)(dropdown_menu_1.DropdownMenuTrigger, { className: 'tw-flex tw-w-[216px] tw-items-center tw-gap-2 tw-rounded tw-bg-background-6 tw-px-2 tw-py-1', disabled: disabled, children: [(0, jsx_runtime_1.jsx)(General_1.CalenderIcon, {}), (0, jsx_runtime_1.jsx)("span", { className: 'tw-mt-1 tw-text-sm tw-text-text-1', children: "Nov 21, 2023 - Dec 18, 2023" })] }), (0, jsx_runtime_1.jsxs)(dropdown_menu_1.DropdownMenuContent, { side: side, align: align, className: (0, utils_1.cn)(contentClassNames, 'tw-w-[216px]'), children: [items_1.items.map(function (item, idx) { return ((0, jsx_runtime_1.jsx)(dropdown_menu_1.DropdownMenuCheckboxItem, { textValue: item.text, onClick: function () { return handleChange(item.value); }, checked: item.value === local, className: 'tw-flex tw-cursor-pointer tw-items-center tw-justify-between', children: (0, jsx_runtime_1.jsx)("div", { className: 'ftw-lex tw-mr-5 tw-items-center tw-gap-3', children: (0, jsx_runtime_1.jsx)("span", { className: 'tw-text-sm', children: item.text }) }) }, idx)); }), (0, jsx_runtime_1.jsxs)(dropdown_menu_1.DropdownMenuSub, { children: [(0, jsx_runtime_1.jsx)(dropdown_menu_1.DropdownMenuSubTrigger, { children: "Custom" }), (0, jsx_runtime_1.jsx)(dropdown_menu_1.DropdownMenuPortal, { children: (0, jsx_runtime_1.jsx)(dropdown_menu_1.DropdownMenuSubContent, { children: (0, jsx_runtime_1.jsx)(date_range_picker_1.DateRangePicker, { initialDateFrom: '2023-01-01', initialDateTo: '2023-12-31', onUpdate: function () { } }) }) })] })] })] }), (0, jsx_runtime_1.jsx)(button_1.Button, { variant: 'grey', size: 'sm', children: "1d" }), (0, jsx_runtime_1.jsx)(button_1.Button, { variant: 'grey', size: 'sm', children: "7d" }), (0, jsx_runtime_1.jsx)(button_1.Button, { variant: 'grey', size: 'sm', children: "30d" })] }));
}
exports.default = DurationDropdownPicker;
