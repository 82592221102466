"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Switch = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var React = __importStar(require("react"));
var SwitchPrimitives = __importStar(require("@radix-ui/react-switch"));
var utils_1 = require("@/lib/utils");
var sizeClasses = {
    small: 'tw-h-[12px] tw-w-[24px]',
    default: 'tw-h-[24px] tw-w-[44px]',
    large: 'tw-h-[32px] tw-w-[64px]',
};
var thumbSizeClasses = {
    small: 'tw-h-[10px] tw-w-[10px]',
    default: 'tw-h-[20px] tw-w-[20px]',
    large: 'tw-h-[28px] tw-w-[28px]',
};
var translateClasses = {
    small: 'data-[state=checked]:tw-translate-x-[11px] data-[state=unchecked]:tw-translate-x-0',
    default: 'data-[state=checked]:tw-translate-x-5 data-[state=unchecked]:tw-translate-x-0',
    large: 'data-[state=checked]:tw-translate-x-[32px] data-[state=unchecked]:tw-translate-x-0',
};
var Switch = React.forwardRef(function (_a, ref) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 'default' : _b, props = __rest(_a, ["className", "size"]);
    return ((0, jsx_runtime_1.jsx)(SwitchPrimitives.Root, __assign({ className: (0, utils_1.cn)('tw-peer tw-inline-flex tw-shrink-0 tw-cursor-pointer tw-items-center tw-rounded-full tw-border-2 tw-border-transparent tw-transition-colors tw-ease-in-out disabled:tw-cursor-not-allowed disabled:tw-opacity-50 data-[state=checked]:tw-bg-accent-1 data-[state=unchecked]:tw-bg-background-7', sizeClasses[size], className) }, props, { ref: ref, children: (0, jsx_runtime_1.jsx)(SwitchPrimitives.Thumb, { role: 'button', "aria-label": 'switch thumb', className: (0, utils_1.cn)('tw-pointer-events-none tw-block tw-rounded-full tw-bg-background-5 tw-shadow-lg tw-ring-0 tw-transition-transform tw-duration-300 tw-ease-in-out data-[state=unchecked]:tw-bg-accent-1', thumbSizeClasses[size], translateClasses[size]) }) })));
});
exports.Switch = Switch;
Switch.displayName = SwitchPrimitives.Root.displayName;
