"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filteredRolesState = exports.filteredUsersState = exports.oldFilteredUsersState = exports.mentionRolesState = exports.mentionUsersState = exports.keywordState = void 0;
var recoil_1 = require("recoil");
/** mention keyword state */
exports.keywordState = (0, recoil_1.atom)({
    key: 'keywordState',
    default: '',
});
/** mention users list */
exports.mentionUsersState = (0, recoil_1.atom)({
    key: 'mentionUserListState',
    default: [],
});
/** mention roles list */
exports.mentionRolesState = (0, recoil_1.atom)({
    key: 'mentionRolesState',
    default: [],
});
/** fitlered users list (not in use) */
exports.oldFilteredUsersState = (0, recoil_1.selector)({
    key: 'filteredUsersState',
    get: function (_a) {
        var get = _a.get;
        var users = get(exports.mentionUsersState);
        var filterText = get(exports.keywordState).toLowerCase();
        if (!filterText) {
            return users;
        }
        return users.filter(function (e) {
            return (e.name && e.name.toLowerCase().includes(filterText)) ||
                (e.nicename && e.nicename.toLowerCase().includes(filterText)) ||
                (e.displayname && e.displayname.toLowerCase().includes(filterText));
        });
    },
});
/** fitlered users list */
exports.filteredUsersState = (0, recoil_1.selector)({
    key: 'filteredUsersState',
    get: function (_a) {
        var get = _a.get;
        var users = get(exports.mentionUsersState);
        var filterText = get(exports.keywordState).toLowerCase();
        if (filterText === 'all') {
            return [];
        }
        if (!filterText) {
            return users;
        }
        var filteredUsersMap = new Map();
        users.forEach(function (user) {
            var _a;
            if ((user.name && user.name.toLowerCase().includes(filterText)) ||
                (user.nicename && user.nicename.toLowerCase().includes(filterText)) ||
                (user.displayname && ((_a = user.displayname) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(filterText)))) {
                filteredUsersMap.set(user.id.toString(), user);
            }
        });
        return Array.from(filteredUsersMap.values());
    },
});
/** fitlered roles list */
exports.filteredRolesState = (0, recoil_1.selector)({
    key: 'filteredRolesState',
    get: function (_a) {
        var get = _a.get;
        var roles = get(exports.mentionRolesState);
        var filterText = get(exports.keywordState).toLowerCase();
        if (!filterText) {
            return roles;
        }
        return roles.filter(function (e) { return e.name && e.name.toLowerCase().includes(filterText); });
    },
});
