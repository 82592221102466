"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Input = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var General_1 = require("@/components/molecules/icons/General");
var utils_1 = require("@/lib/utils");
var lucide_react_1 = require("lucide-react");
var React = __importStar(require("react"));
/**
 * Solid Desgin Input
 */
var Input = React.forwardRef(function (_a, ref) {
    var _b;
    var containerClassName = _a.containerClassName, className = _a.className, type = _a.type, name = _a.name, value = _a.value, maxLength = _a.maxLength, label = _a.label, subLabel = _a.subLabel, error = _a.error, success = _a.success, ghost = _a.ghost, isSearchInput = _a.isSearchInput, icon = _a.icon, rightIcon = _a.rightIcon, onClear = _a.onClear, isPasswordInput = _a.isPasswordInput, passwordToggle = _a.passwordToggle, showCount = _a.showCount, showOptional = _a.showOptional, labelClassName = _a.labelClassName, diffBackground = _a.diffBackground, readOnly = _a.readOnly, props = __rest(_a, ["containerClassName", "className", "type", "name", "value", "maxLength", "label", "subLabel", "error", "success", "ghost", "isSearchInput", "icon", "rightIcon", "onClear", "isPasswordInput", "passwordToggle", "showCount", "showOptional", "labelClassName", "diffBackground", "readOnly"]);
    return ((0, jsx_runtime_1.jsxs)("div", { className: (0, utils_1.cn)('tw-relative tw-flex tw-w-full tw-flex-col tw-items-start tw-transition-all tw-duration-300 tw-ease-in-out', containerClassName), children: [label && ((0, jsx_runtime_1.jsxs)("label", { className: (0, utils_1.cn)('tw-mb-2 tw-inline-block tw-text-sm tw-font-[500] tw-text-text-1', labelClassName), htmlFor: name, children: [label, !subLabel && showOptional && (0, jsx_runtime_1.jsx)("span", { className: 'tw-ml-1 tw-text-xs tw-text-text-4', children: "(Optional)" })] })), subLabel && ((0, jsx_runtime_1.jsxs)("label", { className: 'tw-mt-1 tw-text-sm tw-font-[500] tw-text-text-3', htmlFor: name, children: [subLabel, showOptional && (0, jsx_runtime_1.jsx)("span", { className: 'tw-ml-1 tw-text-xs tw-text-text-4', children: "(Optional)" })] })), icon && (0, jsx_runtime_1.jsx)("div", { className: 'tw-absolute tw-left-3 tw-top-[25%] tw-z-[999]', children: icon }), rightIcon && (0, jsx_runtime_1.jsx)("div", { className: 'tw-absolute tw-right-3 tw-top-[28%]', children: rightIcon }), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-relative tw-w-full', children: [(0, jsx_runtime_1.jsx)("input", __assign({ type: type, name: name, id: name, value: value, spellCheck: false, maxLength: maxLength, readOnly: readOnly, className: (0, utils_1.cn)('tw-peer tw-flex tw-w-full tw-rounded-md tw-border tw-border-background-6 tw-bg-background-6 tw-px-3 tw-py-2 tw-text-sm tw-text-text-1 file:tw-border-0 file:tw-bg-transparent file:tw-text-sm file:tw-font-medium placeholder:tw-text-customgrey hover:tw-border-accent-1 focus-visible:tw-border focus-visible:tw-border-accent-1 focus-visible:tw-outline-none focus-visible:tw-ring-0 disabled:tw-cursor-not-allowed disabled:tw-opacity-50', className, error ? 'tw-border tw-border-error-1 hover:tw-border-error-2 focus-visible:tw-border-error-1' : '', success
                            ? 'tw-border tw-border-success-1 hover:tw-border-r-error-2 focus-visible:tw-border-success-1'
                            : '', isSearchInput
                            ? 'tw-border tw-border-background-7 hover:tw-border-background-7 focus-visible:tw-border-background-7'
                            : '', icon ? 'tw-pl-9' : '', rightIcon ? 'tw-pr-9' : '', ghost
                            ? error
                                ? 'tw-border tw-border-error-1'
                                : 'hover:tw-accent-1 tw-border tw-border-background-7 tw-bg-transparent focus-visible:tw-border-accent-1'
                            : '', diffBackground ? '!tw-bg-background-5' : ''), ref: ref }, props)), isPasswordInput ? ((0, jsx_runtime_1.jsx)("div", { className: (0, utils_1.cn)('[&>svg]:h-4 peeer-disabled:!tw-opacity-50 tw-absolute tw-right-3 tw-top-1/2 -tw-translate-y-1/2 tw-cursor-pointer peer-disabled:!tw-cursor-not-allowed [&>svg]:tw-w-4 [&>svg]:tw-fill-customgrey [&>svg]:hover:tw-fill-customgrey/90'), onClick: passwordToggle, children: type === 'password' ? ((0, jsx_runtime_1.jsx)(lucide_react_1.EyeIcon, { className: '!tw-h-4', style: { fill: 'var(--background-6)' } })) : ((0, jsx_runtime_1.jsx)(lucide_react_1.EyeOff, { className: '!tw-h-4' })) })) : null] }), type === 'search' ? ((0, jsx_runtime_1.jsx)("div", { className: 'tw-absolute tw-right-3 tw-top-[60%]', role: 'button', "aria-label": 'clear input', onClick: onClear, children: (0, jsx_runtime_1.jsx)(General_1.CrossSVG, {}) })) : null, error && !icon ? ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-mt-1 tw-flex tw-items-center tw-gap-1', children: [(0, jsx_runtime_1.jsx)(General_1.CircleErrorSVG, {}), (0, jsx_runtime_1.jsx)("span", { className: 'tw-text-xs tw-text-error-1', children: error })] })) : null, success && !icon ? ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-mt-1 tw-flex tw-items-center tw-gap-1', children: [(0, jsx_runtime_1.jsx)(General_1.CircleSuccessSVG, {}), (0, jsx_runtime_1.jsx)("span", { className: 'tw-text-xs tw-text-success-1', children: success })] })) : null, showCount ? ((0, jsx_runtime_1.jsxs)("span", { className: 'tw-mt-2 tw-w-full tw-text-right tw-text-xs', children: [(_b = value === null || value === void 0 ? void 0 : value.toString()) === null || _b === void 0 ? void 0 : _b.length, "/", maxLength] })) : null] }));
});
exports.Input = Input;
Input.displayName = 'Input';
