"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var button_1 = require("@/components/ui/button");
var dialog_1 = require("@/components/ui/dialog");
var utils_1 = require("@/lib/utils");
/**
 * Action Modal for confirming processes
 * @description Example usage can be found at `ProfileSettingNavigation.tsx`
 */
function ActionModal(_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, title = _a.title, _b = _a.titleAlign, titleAlign = _b === void 0 ? 'left' : _b, children = _a.children, onConfirm = _a.onConfirm, confirmText = _a.confirmText, confirmType = _a.confirmType, onCancel = _a.onCancel, cancelText = _a.cancelText, disableButton = _a.disableButton, className = _a.className, btnClassName = _a.btnClassName;
    return ((0, jsx_runtime_1.jsx)(dialog_1.Dialog, { open: isOpen, onOpenChange: onClose, children: (0, jsx_runtime_1.jsxs)(dialog_1.DialogContent, { className: (0, utils_1.cn)('tw-overflow-hidden !tw-p-0', className), children: [(0, jsx_runtime_1.jsx)(dialog_1.DialogHeader, { className: 'tw-border-b tw-border-customgrey tw-px-6 tw-py-6', children: (0, jsx_runtime_1.jsx)(dialog_1.DialogTitle, { className: "tw-text-lg tw-text-".concat(titleAlign), children: title }) }), (0, jsx_runtime_1.jsx)(dialog_1.DialogDescription, { className: 'tw-px-6 tw-pb-6 tw-text-text-1', children: children }), (0, jsx_runtime_1.jsx)(dialog_1.DialogFooter, { className: 'tw-px-6 tw-pb-6', children: (0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-3', children: [onCancel ? ((0, jsx_runtime_1.jsx)(button_1.Button, { variant: 'link', onClick: onCancel, children: cancelText || 'Cancel' })) : null, (0, jsx_runtime_1.jsx)(button_1.Button, { variant: confirmType === 'danger'
                                    ? 'danger'
                                    : confirmType === 'warn'
                                        ? 'warn'
                                        : confirmType === 'success'
                                            ? 'success'
                                            : 'default', disabled: disableButton, onClick: onConfirm, className: btnClassName, children: confirmText })] }) })] }) }));
}
exports.default = ActionModal;
