"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.items = void 0;
exports.items = [
    {
        value: 'today',
        text: 'Today',
    },
    {
        value: 'yesterday',
        text: 'Yesterday',
    },
    {
        value: 'last7days',
        text: 'Last 7 Days',
    },
    {
        value: 'thisWeek',
        text: 'This Week',
    },
    {
        value: 'last2Weeks',
        text: 'Last 2 Weeks',
    },
    {
        value: 'thisMonth',
        text: 'This MOnth',
    },
    {
        value: 'last30Days',
        text: 'Last 30 Days',
    },
    {
        value: 'lastMonth',
        text: 'Last Month',
    },
    {
        value: 'last60days',
        text: 'Last 60 Days',
    },
    {
        value: 'last90days',
        text: 'Last 90 Days',
    },
    {
        value: 'thisYears',
        text: 'This Years',
    },
];
